import React, { FC } from 'react';
import Booking from '../Booking';

import text from '../text';
import './UnscheduledDetailsStyle.css';

interface Props {
  /** The currently selected unscheduled bookinginfo. */
  booking?: UnscheduledBooking;
}

const UnscheduledBubble: FC<Props> = ({ booking }) => {
  if (!booking) {
    return null;
  }
  return (
    <div className="Bubble BookingsBubble">
      <div className="BubbleHeader">
        <p className="BubbleTitle">{text('unscheduled_single')}</p>
      </div>
      <Booking bookings={[booking.pickup, booking.dropoff]} />
    </div>
  );
};

export default UnscheduledBubble;
