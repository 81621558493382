const REGEX_TYPE_LETTERS = '(BB|B|P)';
const PARA_RUN_NAME_REGEX = new RegExp(
  '^([a-zA-ZÅÄÖ]{1,7})([0-9]{1,3})' + REGEX_TYPE_LETTERS,
);

export const paraRunNameToUnitTypeNumber = (para_run_name: string): number => {
  const matched = PARA_RUN_NAME_REGEX.exec(para_run_name);
  if (!matched || !matched[3]) {
    throw new Error('BT: Invalid paraRunName for unit type: ' + para_run_name);
  }
  const type_letters = matched[3];
  const type_map: { [index: string]: number } = {
    B: 1,
    BB: 2,
    P: 3,
  };

  if (!Object.prototype.hasOwnProperty.call(type_map, type_letters)) {
    throw new Error('BT: Invalid type letters of paraRunName: ' + type_letters);
  }

  return type_map[type_letters];
};

export const numberTypeToUnitType = (unit_type_number: number): UnitType => {
  switch (unit_type_number) {
    case 2:
      return 'minibus_w_bed';
    case 1:
      return 'minibus';
    case 3:
      return 'car';
    default: {
      throw new Error('BT: Invalid type in UnitInfo: ' + unit_type_number);
    }
  }
};

export const getGroup = (ParaRunName: string): number => {
  if (ParaRunName) {
    return 0;
  }
  return 0;
};

export const filterValidUnits = (u: UnitInfoRes): boolean => {
  if (PARA_RUN_NAME_REGEX.test(u.ParaRunName)) {
    return true;
  }
  return false;
};

export const filterBusses = (b: VehicleStateRes): boolean => {
  return b.Datasource === 'ConsatBT';
};

const capacity_tooltips: {
  [key: string]: string;
} = {
  BH: 'Bärhjälp',
  EL: 'Ensamåkning liggande',
  EN: 'Ensamåkning oavsett fordon',
  EP: 'Ensamåkning personbil',
  ER: 'Ensamåkning rullstol',
  FS: 'Fällbart säte',
  GB: 'Gående buss',
  GF: 'Gående fällbar rullstol',
  GR: 'Rullstol går inte att fälla',
  GÅ: 'Gående',
  L: 'Liggande transport',
  LP: 'Paket åt landstinget',
  LT: 'Sjukbuss åt landstinget',
  MT: 'Bära matkasse',
  PB: 'Måste åka personbil',
  RB: 'Stor rullstol/permobil',
  RS: 'Rullstol',
  TR: 'Trappklättrare',
  XB: 'Extra plats för ben',
};

export const convertUnitCapacity = (res: UnitCapacityRes): UnitCapacity => {
  const tooltip = Object.prototype.hasOwnProperty.call(
    capacity_tooltips,
    res.Name,
  )
    ? capacity_tooltips[res.Name]
    : undefined;
  return {
    max: res.Max,
    min: res.Min,
    name: res.Name,
    type: res.Type,
    tooltip,
    badge: true,
  };
};
