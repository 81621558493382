import React, { FC, useState, useEffect, useReducer, useCallback } from 'react';
import { RouteChildrenProps, match } from 'react-router';

import './MainPanelStyle.css';

import {
  fetchVehicleDetails,
  hasBookingsChanged,
} from './selectedVehicleDetailsFunctions';
import vehicleReducer from './vehicle_state';
import busReducer from './bus_state';
import disruptionReducer from './disruption_state';
import { getInitGarageState } from './garage_state';
import createOldApi from '../api/old';
import ws from '../disruption_notifications/ws';

import Map from '../Map';
import LeftPanel from '../LeftPanel';
import VehicleDetailsBubble from '../VehicleDetailsBubble';
import Legend, { legendTypes } from '../Legend';
import Search from '../Search';
import { GMW_ExportedFunctions } from 'google-maps-wrapper';
import DisruptionDetails from '../DisruptionDetails';
import BusDetails from '../BusDetails';
import AddressVehicleDetailsBubble from '../AddressVehicleDetailsBubble';
import UnscheduledDetails from '../UnscheduledDetails';

import withConfig from 'with-config';
import { Config } from '../config';
import { fetchBusDetails } from './selectedBusDetailsFunctions';
import GarageDetails from '../GarageDetails';

let get_all_units_request_fails = 0;
let get_busses_request_fails = 0;
let get_unscheduled_request_fails = 0;

interface Props extends RouteChildrenProps {
  match: match<{ vehicle_id: string }>;
}

const onMarkerClick = (
  type: MarkerType,
  id: string | number,
  show_state: ShowState,
  setSelectedDisruptionId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >,
  setSelectedGarageId: React.Dispatch<React.SetStateAction<number | undefined>>,
  setSelectedBusId: React.Dispatch<React.SetStateAction<string | undefined>>,
  setSelectedVehicleId: React.Dispatch<
    React.SetStateAction<VehicleSelection | null>
  >,
  setSelectedUnscheduledId: React.Dispatch<React.SetStateAction<number | null>>,
  setShowState: React.Dispatch<React.SetStateAction<ShowState>>,
): boolean => {
  if (type === 'vehicle') {
    const parts = (id as string).split('|');
    setSelectedVehicleId({
      vehicle_id: parts[0],
      unit_id: parts[1],
    });
    setSelectedBusId(undefined);
    setSelectedGarageId(undefined);
    setShowState({
      ...show_state,
      vehicles: false,
      busses: false,
      garage: false,
    });
    return true;
  } else if (type === 'disruption') {
    setSelectedDisruptionId(id as number);
    setSelectedBusId(undefined);
    setSelectedGarageId(undefined);
    return false;
  } else if (type === 'unscheduled') {
    setSelectedUnscheduledId(id as number);
    setSelectedBusId(undefined);
    setSelectedGarageId(undefined);
    return false;
  } else if (type === 'bus') {
    setSelectedBusId(id as string);
    setSelectedUnscheduledId(null);
    setSelectedDisruptionId(undefined);
    setSelectedGarageId(undefined);
    return false;
  } else if (type === 'garage') {
    setSelectedGarageId(id as number);
    return false;
  }
  return false;
};

const onLegendGroupClick = (
  group: number,
  enabled_groups: Set<number>,
  setEnabledGroups: React.Dispatch<React.SetStateAction<Set<number>>>,
): void => {
  const new_set = new Set(enabled_groups);
  if (!enabled_groups.has(group)) {
    new_set.add(group);
  } else {
    new_set.delete(group);
  }
  setEnabledGroups(new_set);
};

const onLegendTypeClick = (
  type: UnitType,
  enabled_types: Set<UnitType>,
  setEnabledTypes: React.Dispatch<React.SetStateAction<Set<UnitType>>>,
): void => {
  const new_set = new Set(enabled_types);
  if (!enabled_types.has(type)) {
    new_set.add(type);
  } else {
    new_set.delete(type);
  }
  setEnabledTypes(new_set);
};

const MainPanel: FC<Props> = () => {
  const config = withConfig.getCurrentConfig() as Config;
  const [show_state, setShowState] = useState<ShowState>({
    ...config.default_visiblity,
  });

  const [saved_show_state, setSavedShowState] = useState<ShowState>({
    ...config.default_visiblity,
  });

  const [selected_disruption_id, setSelectedDisruptionId] = useState<
    number | undefined
  >(undefined);
  const [selected_garage_id, setSelectedGarageId] = useState<
    number | undefined
  >(undefined);
  const [selected_bus_id, setSelectedBusId] = useState<string | undefined>(
    undefined,
  );
  const [garage_state] = useState<GarageState>(getInitGarageState(config));
  const [
    address_to_address_search_result,
    setAddressToAddressSearchResult,
  ] = useState<AddressToAddressSearchResults | undefined>(undefined);
  const [
    address_to_vehicle_search_result,
    setAddressToVehicleSearchResult,
  ] = useState<AddressToVehicleSearchResults | undefined>(undefined);
  const [map_funcs, setMapFuncs] = useState<GMW_ExportedFunctions | null>(null);
  const [enabled_groups, setEnabledGroups] = useState<Set<number>>(
    new Set([0, 1, 2, 3, 4, 5, 6, 7, 8]),
  );
  const [enabled_types, setEnabledTypes] = useState<Set<UnitType>>(
    new Set(legendTypes(config)),
  );
  const [unscheduled, setUnscheduled] = useState<UnscheduledBooking[]>([]);
  const [selected_unscheduled_id, setSelectedUnscheduledId] = useState<
    number | null
  >(null);
  const [
    selected_vehicle_id,
    setSelectedVehicleId,
  ] = useState<VehicleSelection | null>(null);
  const [vehicle_state, dispatchVehicle] = useReducer(vehicleReducer, {
    all: [],
    by_id: {},
  });
  const [bus_state, dispatchBus] = useReducer(busReducer, {
    all: [],
    by_id: {},
  });
  const [disruption_state, dispatchDisruption] = useReducer(disruptionReducer, {
    all: [],
    by_id: {},
  });
  const [
    selected_vehicle_details,
    setSelectedVehicleDetails,
  ] = useState<SelectedVehicleDetails | null>(null);
  const [
    fetched_vehicle_details,
    setFetchedVehicleDetails,
  ] = useState<FetchedVehicleDetails | null>(null);
  const [selected_bus_details, setSelectedBusDetails] = useState<
    FetchedBusDetails | undefined
  >(undefined);

  const closePopups = useCallback(() => {
    setSelectedVehicleId(null);
    setSelectedVehicleDetails(null);
    setSelectedUnscheduledId(null);
    setSelectedDisruptionId(undefined);
    setSelectedGarageId(undefined);
    setAddressToVehicleSearchResult(undefined);
    setAddressToAddressSearchResult(undefined);
    setSelectedBusId(undefined);
    setSelectedBusDetails(undefined);
    if (saved_show_state) {
      setShowState(saved_show_state);
    }
  }, [
    saved_show_state,
    setSelectedVehicleId,
    setAddressToAddressSearchResult,
    setAddressToVehicleSearchResult,
    setSelectedVehicleDetails,
    setSelectedUnscheduledId,
    setSelectedDisruptionId,
    setShowState,
  ]);

  useEffect(() => {
    ws.prepare(dispatchDisruption);
    ws.start(config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchUnscheduled = (): void => {
      const oldApi = createOldApi(config);
      oldApi
        .getUnscheduledBookings()
        .then((unscheds) => {
          setUnscheduled(unscheds);
          get_unscheduled_request_fails = 0;
        })
        .catch((err) => {
          get_unscheduled_request_fails++;
          if (get_unscheduled_request_fails >= 5) {
            throw err;
          }
          console.error(
            'Request getAllUnits have failed ' +
              get_unscheduled_request_fails +
              ' times.',
          );
        });
    };
    fetchUnscheduled();
    const get_unscheduled_interval = setInterval(() => {
      fetchUnscheduled();
    }, config.unscheduled_update_frequency * 1000);
    return () => {
      clearInterval(get_unscheduled_interval);
    };
  }, [config]);

  useEffect(() => {
    const fetchAll = (): void => {
      const oldApi = createOldApi(config);
      oldApi
        .getAllUnits()
        .then((unit_updates) => {
          dispatchVehicle({ type: 'set', unit_updates });
          get_all_units_request_fails = 0;
        })
        .catch((err) => {
          get_all_units_request_fails++;
          if (get_all_units_request_fails >= 5) {
            throw err;
          }
          console.error(
            'Request getAllUnits have failed ' +
              get_all_units_request_fails +
              ' times.',
          );
        });
    };
    fetchAll();
    const get_all_units_interval = setInterval(() => {
      fetchAll();
    }, config.unit_update_frequency * 1000);
    return () => {
      clearInterval(get_all_units_interval);
    };
  }, [config]);
  useEffect(() => {
    const fetchBusses = (): void => {
      const oldApi = createOldApi(config);
      oldApi
        .getAVLState()
        .then((bus_updates) => {
          dispatchBus({ type: 'set', bus_updates });
          get_busses_request_fails = 0;
        })
        .catch((err) => {
          get_busses_request_fails++;
          if (get_busses_request_fails >= 5) {
            throw err;
          }
          console.error(
            'Request getAVLState (busses) have failed ' +
              get_busses_request_fails +
              ' times.',
          );
        });
    };
    fetchBusses();
    const get_busses_interval = setInterval(() => {
      fetchBusses();
    }, config.bus_update_frequency * 1000);
    return () => {
      clearInterval(get_busses_interval);
    };
  }, [config]);

  useEffect(() => {
    if (!selected_vehicle_id) {
      return;
    }
    const updateBookings = (): void => {
      fetchVehicleDetails(selected_vehicle_id.unit_id, config)
        .then((new_details) => {
          setFetchedVehicleDetails(new_details);
        })
        .catch((err) => {
          throw err;
        });
    };
    const update_bookings_interval = setInterval(() => {
      updateBookings();
    }, config.bookings_update_frequency * 1000);
    return (): void => {
      clearInterval(update_bookings_interval);
    };
  }, [selected_vehicle_id, config]);

  useEffect(() => {
    if (!selected_bus_id) {
      return;
    }
    const updateBusDetails = (): void => {
      fetchBusDetails(selected_bus_id, config)
        .then((new_details) => {
          setSelectedBusDetails(new_details);
        })
        .catch((err) => {
          throw err;
        });
    };
    updateBusDetails();
    const update_bus_interval = setInterval(() => {
      updateBusDetails();
    }, config.selected_bus_update_frequency * 1000);
    return (): void => {
      clearInterval(update_bus_interval);
    };
  }, [selected_bus_id, config]);

  useEffect(() => {
    if (!selected_vehicle_details || !fetched_vehicle_details) {
      //Return early if we dont have details.
      return;
    }
    if (
      hasBookingsChanged(
        selected_vehicle_details.bookings,
        fetched_vehicle_details.bookings,
      )
    ) {
      console.log('2. Bookings for selected vehicle has been updated.');
      setSelectedVehicleDetails(
        Object.assign(
          {},
          { vehicle: selected_vehicle_details.vehicle },
          fetched_vehicle_details,
        ),
      );
      setFetchedVehicleDetails(null);
    }
  }, [fetched_vehicle_details, selected_vehicle_details]);

  useEffect(() => {
    if (!selected_vehicle_id) {
      //Early out if we are not viewing a vehicle.
      return;
    }

    if (
      selected_vehicle_details !== null &&
      selected_vehicle_details.vehicle.vehicle_id ===
        selected_vehicle_id.vehicle_id
    ) {
      //This should only happen if we are already viewing a specific vehicle and we have recieved new unit updates.
      //We need to update the trace for the selected vehicle.
      const vehicle_id = selected_vehicle_details.vehicle.vehicle_id;
      const vehicle = vehicle_state.by_id[vehicle_id];
      const trace = [vehicle.location, ...selected_vehicle_details.trace].slice(
        0,
        config.selected_vehicle_trace_steps,
      );
      setSelectedVehicleDetails(
        Object.assign({}, selected_vehicle_details, {
          trace: trace,
          vehicle: vehicle,
        }),
      );
      return;
    }

    console.log('New vehicle selected.');
    //New vehicle has been selected.
    const vehicle = vehicle_state.by_id[selected_vehicle_id.vehicle_id];
    if (!vehicle) {
      //This vehicle_id does not exist in vehicle_state.
      console.log('Vehicle not in state.', selected_vehicle_id, vehicle_state);
      return;
    }
    fetchVehicleDetails(vehicle.unit_id, config)
      .then((details) => {
        setSelectedVehicleDetails(Object.assign({}, { vehicle }, details));
      })
      .catch((err) => {
        throw err;
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_vehicle_id, vehicle_state]);

  const selected_unscheduled = unscheduled.find(
    (u) => u.pickup.booking_id === selected_unscheduled_id,
  );

  const resetSettings = (): void => {
    setEnabledGroups(new Set([0, 1, 2, 3, 4, 5, 6, 7, 8]));
    setEnabledTypes(
      new Set(['car', 'minibus', 'minibus_w_bed', 'stairclimber']),
    );

    setSelectedVehicleId(null);
    setSelectedVehicleDetails(null);
    setSelectedBusId(undefined);
    setSelectedBusDetails(undefined);
    setSelectedUnscheduledId(null);
    setSelectedDisruptionId(undefined);
    setAddressToAddressSearchResult(undefined);
    setAddressToVehicleSearchResult(undefined);
    setSelectedGarageId(undefined);
    console.log('config.default_visiblity:', config.default_visiblity);
    setShowState({
      ...config.default_visiblity,
    });
  };

  return (
    <>
      <div className="MainPanel">
        <Map
          vehicles={vehicle_state.all}
          onMarkerClick={(type: MarkerType, id: string | number) =>
            onMarkerClick(
              type,
              id,
              show_state,
              setSelectedDisruptionId,
              setSelectedGarageId,
              setSelectedBusId,
              setSelectedVehicleId,
              setSelectedUnscheduledId,
              setShowState,
            )
          }
          unscheduled={unscheduled}
          busses={bus_state.all}
          garages={garage_state.all}
          disruptions={disruption_state.all}
          selected_vehicle_details={selected_vehicle_details || undefined}
          selected_garage_details={
            typeof selected_garage_id === 'number'
              ? garage_state.by_id[selected_garage_id]
              : undefined
          }
          selected_bus_details={
            selected_bus_id ? selected_bus_details : undefined
          }
          enabled_groups={enabled_groups}
          enabled_types={enabled_types}
          init_cb={(funcs) => setMapFuncs(funcs)}
          show_disruptions={show_state.disruptions}
          show_unscheduled={show_state.unscheduled}
          show_vehicles={show_state.vehicles}
          show_busses={show_state.busses}
          show_garages={show_state.garage}
          address_to_address_search_result={address_to_address_search_result}
          address_to_vehicle_search_result={address_to_vehicle_search_result}
          onMapClick={closePopups}
        />
      </div>
      <LeftPanel
        amounts={{
          vehicles: vehicle_state.all.length,
          disruptions: disruption_state.all.length,
          busses: bus_state.all.length,
          unscheduled: unscheduled.length,
          garage: garage_state.all.length,
        }}
        show_state={show_state}
        setShowState={(show_state: ShowState) => {
          setSavedShowState(show_state);
          setShowState(show_state);
        }}
        available_tabs={config.available_tabs}
        onReset={resetSettings}
      />
      <UnscheduledDetails booking={selected_unscheduled} />
      <BusDetails
        details={selected_bus_id ? selected_bus_details : undefined}
      />
      <GarageDetails
        garage={
          typeof selected_garage_id === 'number'
            ? garage_state.by_id[selected_garage_id]
            : undefined
        }
      />
      {selected_vehicle_details && (
        <VehicleDetailsBubble
          bookings={selected_vehicle_details.bookings}
          vehicle={selected_vehicle_details.vehicle}
        />
      )}
      {(address_to_vehicle_search_result ||
        address_to_address_search_result) && (
        <AddressVehicleDetailsBubble
          address_to_address_search_result={address_to_address_search_result}
          address_to_vehicle_search_result={address_to_vehicle_search_result}
        />
      )}
      <Legend
        show={show_state.vehicles}
        enabled_groups={enabled_groups}
        enabled_types={enabled_types}
        vehicles={vehicle_state.all}
        onGroupClick={(group) =>
          onLegendGroupClick(group, enabled_groups, setEnabledGroups)
        }
        onTypeClick={(type) =>
          onLegendTypeClick(type, enabled_types, setEnabledTypes)
        }
      />
      {map_funcs && (
        <Search
          show={true}
          show_state={show_state}
          vehicles={vehicle_state}
          map_funcs={map_funcs}
          setSelectedVehicleId={(id) => {
            if (id !== selected_vehicle_id) {
              setFetchedVehicleDetails(null);
              setSelectedVehicleId(id);
            }
          }}
          setShowState={setShowState}
          onSearched={(address_to_address, address_to_vehicle) => {
            setSelectedVehicleId(null);
            setSelectedVehicleDetails(null);
            setSelectedUnscheduledId(null);
            setSelectedDisruptionId(undefined);
            setSelectedBusDetails(undefined);
            setSelectedBusId(undefined);
            setAddressToAddressSearchResult(address_to_address);
            setAddressToVehicleSearchResult(address_to_vehicle);

            if (!address_to_address && !address_to_vehicle) {
              closePopups();
              return;
            }

            if (address_to_vehicle && address_to_vehicle.vehicle_id) {
              const vehicle =
                vehicle_state.by_id[address_to_vehicle.vehicle_id];
              if (vehicle) {
                setSelectedVehicleId({
                  unit_id: vehicle.unit_id,
                  vehicle_id: vehicle.vehicle_id,
                });
              }
            }
            setShowState({ ...show_state, vehicles: false });
          }}
        />
      )}
      {selected_disruption_id !== undefined &&
        disruption_state.by_id[selected_disruption_id] && (
          <DisruptionDetails
            disruption={disruption_state.by_id[selected_disruption_id]}
          />
        )}
    </>
  );
};

export default withConfig(MainPanel as any);
