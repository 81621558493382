import React, { FC } from 'react';
import { Text } from '@blueprintjs/core';
import './DisruptionDetailsStyle.css';
import { ReactComponent as IconRoadWorks } from '../img/disruptions/road_work.svg';
import { ReactComponent as IconLimited } from '../img/disruptions/limited.svg';
import { ReactComponent as IconBlocked } from '../img/disruptions/blocked.svg';

interface DisruptionDetailsProps {
  disruption: Disruption;
}

const getIcon = (disruption_type: DisruptionType): any => {
  switch (disruption_type) {
    case 'road_works':
      return <IconRoadWorks />;
    case 'limited':
      return <IconLimited />;
    case 'blocked':
      return <IconBlocked />;
    default: {
      return <IconBlocked />;
    }
  }
};

const DisruptionDetails: FC<DisruptionDetailsProps> = ({ disruption }) => {
  return (
    <div className="Bubble">
      <div className="BubbleHeader --Small">
        <div className="BubbleGraphics">{getIcon(disruption.type)}</div>
        <p className="BubbleTitle">{disruption.title}</p>
      </div>
      <div className="DisruptionDetails">
        <p className="Pill Inline">{disruption.start}</p>
        <p className="PillDivider">-</p>
        <p className="Pill Inline">{disruption.end}</p>
        <Text className="PreWrap">{disruption.description}</Text>
      </div>
    </div>
  );
};

export default DisruptionDetails;
