import React, { FC, useState } from 'react';
import { Button, InputGroup } from '@blueprintjs/core';
import {
  executeAddressToAddressSearch,
  executeAddressToVehicleSearch,
} from './search_funcs';
import oh from 'output-helpers';
import moment from 'moment';

import text from '../text';
import VehicleSelect from './VehicleSelect';

const doDualSearch = (
  map_funcs: ExportedFuncs,
  setValueOne: React.Dispatch<React.SetStateAction<string>>,
  setValueTwo: React.Dispatch<React.SetStateAction<string>>,
  vehicles: VehicleState,
  second_type: 'vehicle' | 'address',
  input_one: string,
  input_two: string,
  onSearched: (
    address_to_address: AddressToAddressSearchResults | undefined,
    address_to_vehicle: AddressToVehicleSearchResults | undefined,
  ) => void,
): void => {
  if (second_type === 'vehicle') {
    executeAddressToVehicleSearch(map_funcs, vehicles, input_one, input_two)
      .then((res) => {
        if (
          res.directions &&
          res.address &&
          res.address.location &&
          res.directions.distance &&
          res.directions.duration &&
          res.vehicle
        ) {
          const distance =
            oh.format(res.directions.distance, {
              round: -1,
              decimal_padding: 1,
            }) + 'km';
          const traveltime = moment('2020-01-01')
            .seconds(res.directions.duration)
            .format('H:mm:ss');
          onSearched(undefined, {
            path: res.directions.path,
            address_pos: res.address.location,
            address_label: res.address.address,
            distance: distance,
            traveltime: traveltime,
            vehicle_id: res.vehicle.vehicle_id,
            unit_id: res.vehicle.unit_id,
          });
        }
        setValueOne(res.address.address);
        if (!res.vehicle) {
          setValueTwo(text('invalid_vehicle_id'));
        }
      })
      .catch((err) => {
        throw err;
      });
  } else {
    executeAddressToAddressSearch(map_funcs, input_one, input_two)
      .then((res) => {
        setValueOne(res.address_one.address);
        setValueTwo(res.address_two.address);
        if (
          !res.directions ||
          !res.directions.duration ||
          !res.directions.distance ||
          !res.address_one.location ||
          !res.address_two.location
        ) {
          return;
        }
        const traveltime = moment('2020-01-01')
          .seconds(res.directions.duration)
          .format('H:mm:ss');
        const distance =
          oh.format(res.directions.distance, {
            round: -1,
            decimal_padding: 1,
          }) + 'km';

        onSearched(
          {
            path: res.directions.path,
            poi_one: res.address_one.location,
            address_one: res.address_one.address,
            poi_two: res.address_two.location,
            address_two: res.address_two.address,
            distance: distance,
            traveltime: traveltime,
          },
          undefined,
        );
      })
      .catch((err) => {
        throw err;
      });
  }
};
interface DualInputSearchProps {
  map_funcs: ExportedFuncs;
  field_one: 'vehicle' | 'address';
  vehicles: VehicleState;
  onClearSearch: () => void;
  field_two: 'vehicle' | 'address';
  onSearched: (
    address_to_address: AddressToAddressSearchResults | undefined,
    address_to_vehicle: AddressToVehicleSearchResults | undefined,
  ) => void;
}
const DualInputSearch: FC<DualInputSearchProps> = ({
  field_one,
  field_two,
  map_funcs,
  vehicles,
  onClearSearch,
  onSearched,
}) => {
  const [value_one, setValueOne] = useState<string>('');
  const [value_two, setValueTwo] = useState<string>('');
  return (
    <div className="AddressSearch">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (value_one.length > 0 && value_two.length > 0) {
            doDualSearch(
              map_funcs,
              setValueOne,
              setValueTwo,
              vehicles,
              field_two,
              value_one,
              value_two,
              onSearched,
            );
          }
        }}
      >
        <div className="FormRow">
          <InputGroup
            large
            placeholder={
              field_one === 'vehicle' ? text('vehicle_id') : text('address')
            }
            rightElement={
              <Button
                icon="delete"
                className="BtnReset"
                onClick={() => {
                  onClearSearch();
                  setValueOne('');
                }}
              />
            }
            value={value_one}
            //Following disable because onChange typing seems wrong, it claims 'ev' is any.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            onChange={(ev: any) => setValueOne(ev.target.value)}
          />
          {field_two === 'vehicle' && (
            <div className="SearchInputBox">
              <VehicleSelect
                onChange={setValueTwo}
                value={value_two}
                defaultValue={text('choose_vehicle_id')}
                vehicles={vehicles}
              />
            </div>
          )}
          {field_two === 'address' && (
            <div className="SearchInputBox">
              <InputGroup
                large
                rightElement={
                  <Button
                    icon="delete"
                    className="BtnReset"
                    onClick={() => {
                      onClearSearch();
                      setValueTwo('');
                    }}
                  />
                }
                placeholder={text('address')}
                value={value_two}
                //Following disable because onChange typing seems wrong, it claims 'ev' is any.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                onChange={(ev: any) => setValueTwo(ev.target.value)}
              />
            </div>
          )}
          <button
            className="Btn SearchPerformSearch"
            type="submit"
            disabled={value_one.length < 1 || value_two.length < 1}
          >
            <p>{text('find')}</p>
          </button>
        </div>
      </form>
    </div>
  );
};

export default DualInputSearch;
