import React, { FC, useEffect, useState } from 'react';
import oh from 'output-helpers';
import { Icon } from '@blueprintjs/core';

import './LeftPanelStyle.css';

import text from '../text';

interface LeftPanelProps {
  amounts: TabAmounts;
  show_state: ShowState;
  available_tabs: AvailableTabs;
  setShowState: (show_state: ShowState) => void;
  onReset?: () => void;
}

interface BtnProps {
  amount: number;
  show_state: ShowState;
  setShowState: (show_state: ShowState) => void;
  tab_id: keyof AvailableTabs;
}
const TabButton: FC<BtnProps> = ({
  show_state,
  amount,
  setShowState,
  tab_id,
}) => {
  return (
    <div
      className={`LeftPanelButton ${show_state[tab_id] ? 'active' : ''}`}
      onClick={() => {
        setShowState({
          ...show_state,
          [tab_id]: !show_state[tab_id],
        });
      }}
    >
      <div className="ButtonNumber">{amount}</div>
      <p className="ButtonName">{text(tab_id, true)}</p>
    </div>
  );
};

const tab_ids: (keyof AvailableTabs)[] = [
  'vehicles',
  'disruptions',
  'unscheduled',
  'busses',
  'garage',
];
const LeftPanel: FC<LeftPanelProps> = ({
  amounts,
  available_tabs,
  show_state,
  setShowState,
  onReset,
}) => {
  const [date, setDate] = useState(new Date());
  useEffect(() => {
    const updateDate = (): void => {
      setDate(new Date());
      setTimeout(() => updateDate(), 60000);
    };
    setTimeout(() => updateDate(), 60000);
    //TODO: Return function which clears timeout.
  }, []);

  return (
    <div className="LeftPanel">
      <div className="NTDLogo" />
      <div className="ButtonContainer">
        {tab_ids.map((tab_id) => {
          return available_tabs[tab_id] ? (
            <TabButton
              key={tab_id}
              amount={amounts[tab_id]}
              show_state={show_state}
              setShowState={setShowState}
              tab_id={tab_id}
            />
          ) : null;
        })}
      </div>
      <div className="LeftPanelButton ResetButton" onClick={onReset}>
        <Icon icon={'reset'} />
        <p className="ButtonName">Återställ</p>
      </div>
      <div className="ClockContainer">
        <p className="ClockTime">{oh.formatDateAsTimeString(date)}</p>
        <div className="ClockDate">
          {oh.formatDateAsString(date, 'YYYY-MM-DD')}
        </div>
      </div>
    </div>
  );
};

export default LeftPanel;
