/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
//Above because of weird typings for google maps geo svc.

import { getDirections } from '../Map/directions';
import text from '../text';
import { Config } from '../config';
import withConfig from 'with-config';

export const addressLookup = (
  map_funcs: ExportedFuncs,
  input: string,
): Promise<AddressLookupResult> => {
  const geo_svc = map_funcs.getServices().geocoderService;

  return new Promise((resolve, reject) => {
    geo_svc.geocode(
      { address: input, componentRestrictions: { country: 'SE' } },
      (res, status) => {
        if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
          reject('Empty.');
        }
        if (status !== google.maps.GeocoderStatus.OK) {
          return reject('Geocoding request failed.');
        }
        console.log('INFO: Goecoding request to google has finished.');
        if (res.length === 0 || res[0].formatted_address.length === 0) {
          resolve({ input: input, address: text('invalid_address') });
        } else {
          resolve({
            input: input,
            address: res[0].formatted_address,
            location: res[0].geometry.location.toJSON(),
            viewport: res[0].geometry.viewport.toJSON(),
          });
        }
      },
    );
  });
};

export const addressToVehicle = (
  map_funcs: ExportedFuncs,
  vehicle: UnitInfo,
  input: string,
): Promise<AddressToVehicleLookupResult> => {
  return new Promise((resolve, reject) => {
    addressLookup(map_funcs, input)
      .then((address) => {
        if (!address.location) {
          return resolve({
            address,
            vehicle,
          });
        }

        return getDirections(map_funcs, [
          address.location,
          vehicle.location,
        ]).then((res) => {
          if (res.path.length === 0) {
            return resolve({
              address,
              vehicle,
            });
          }
          return resolve({
            address,
            vehicle,
            directions: res,
          });
        });
      })
      .catch(() => {
        return reject('AddressToVehicle lookup failed.');
      });
  });
};

export const addressToAddress = (
  map_funcs: ExportedFuncs,
  input_one: string,
  input_two: string,
): Promise<[AddressLookupResult, AddressLookupResult]> => {
  return new Promise((resolve, reject) => {
    Promise.all([
      addressLookup(map_funcs, input_one),
      addressLookup(map_funcs, input_two),
    ])
      .then((addresses) => {
        resolve(addresses);
      })
      .catch(() => {
        return reject('AddressToAddress lookup failed.');
      });
  });
};

export const executeAddressSearch = async (
  map_funcs: ExportedFuncs,
  input: string,
): Promise<AddressLookupResult> => {
  const address = await addressLookup(map_funcs, input);
  if (address.location) {
    const marker = await map_funcs.setMarker('address_search_marker', {
      default: {
        position: address.location,
      },
    });
    marker.zoomTo();
  }
  return Promise.resolve(address);
};

export const executeVehicleSearch = async (
  map_funcs: ExportedFuncs,
  vehicles: VehicleState,
  input: string,
): Promise<UnitInfo> => {
  const config = withConfig.getCurrentConfig() as Config;
  const vehicle = vehicles.all.find(
    (v) =>
      v.unit_id === input ||
      v.vehicle_id === input ||
      v.vehicle_number === input,
  );
  if (!vehicle) {
    return Promise.reject('Not in state.');
  }
  Promise.all([
    map_funcs.setCenter(vehicle.location),
    map_funcs.setZoom(config.vehicle_search_result_zoom),
  ]).catch((err) => {
    throw err;
  });
  return Promise.resolve(vehicle);
};

/** This function resolves to an object with a path. The path is not plotted by this function! */
export const executeAddressToVehicleSearch = async (
  map_funcs: ExportedFuncs,
  vehicles: VehicleState,
  street_input: string,
  vehicle_input: string,
): Promise<AddressToVehicleLookupResult> => {
  const vehicle = vehicles.all.find(
    (v) =>
      v.unit_id === vehicle_input ||
      v.vehicle_id === vehicle_input ||
      v.vehicle_number === vehicle_input,
  );
  if (!vehicle) {
    const address = await addressLookup(map_funcs, street_input);
    return Promise.resolve({
      address,
    });
  }
  const addrToVeh = await addressToVehicle(map_funcs, vehicle, street_input);
  if (addrToVeh.directions && addrToVeh.directions.bounds) {
    map_funcs.setBounds(addrToVeh.directions.bounds).catch((err) => {
      throw err;
    });
  }
  return Promise.resolve(addrToVeh);
};

export const executeAddressToAddressSearch = async (
  map_funcs: ExportedFuncs,
  input_one: string,
  input_two: string,
): Promise<AddressToAddressLookupResult> => {
  const [address_one, address_two] = await Promise.all([
    addressLookup(map_funcs, input_one),
    addressLookup(map_funcs, input_two),
  ]);
  if (!address_one.location || !address_two.location) {
    return Promise.resolve({
      address_one,
      address_two,
    });
  }
  const directions = await getDirections(map_funcs, [
    address_one.location,
    address_two.location,
  ]);
  if (directions.path && directions.bounds) {
    map_funcs.setBounds(directions.bounds).catch((err) => {
      throw err;
    });
  }
  return Promise.resolve({
    address_one,
    address_two,
    directions: directions,
  });
};
