import {
  st_dictionary,
  bt_dictionary,
  TextKeys as TextKeys2,
  translate,
  Dictionary as Dictionary2,
} from './text';

export { st_dictionary, bt_dictionary };
export type TextKeys = TextKeys2;
export type Dictionary = Dictionary2;
export default translate;
