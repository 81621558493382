import React from 'react';

import text from '../text';
import './AddressVehicleDetailsBubbleStyles.css';
import AddressToAddressInfo from './AddressToAddressInfo';
import AddressToVehicleInfo from './AddressToVehicleInfo';

interface Props {
  address_to_address_search_result?: AddressToAddressSearchResults;
  address_to_vehicle_search_result?: AddressToVehicleSearchResults;
}

const AddressVehicleDetailsBubble: React.FC<Props> = ({
  address_to_address_search_result,
  address_to_vehicle_search_result,
}) => {
  return (
    <div className="Bubble AddressVehicleDetailsBubble">
      <div className="BubbleHeader">
        <p className="BubbleTitle">{text('search_result')}</p>
      </div>
      <div className="Content">
        {address_to_address_search_result && (
          <AddressToAddressInfo
            address_to_address={address_to_address_search_result}
          />
        )}
        {address_to_vehicle_search_result && (
          <AddressToVehicleInfo
            address_to_vehicle={address_to_vehicle_search_result}
          />
        )}
      </div>
    </div>
  );
};

export default AddressVehicleDetailsBubble;
