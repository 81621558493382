import React, { FC, useEffect, useState } from 'react';
import { ButtonGroup } from '@blueprintjs/core';

import './SearchStyle.css';
import SearchTypeButton from './SearchTypeButton';
import SingleInputSearch from './SingleInputSearch';
import DualInputSearch from './DualInputSearch';
import { ReactComponent as Car } from '../img/search/search.svg';
//import { Simulate } from 'react-dom/test-utils';

export type SearchType =
  | 'none'
  | 'address'
  | 'vehicle'
  | 'address_to_vehicle'
  | 'address_to_address';

interface SearchProps {
  show: boolean;
  show_state: ShowState;
  vehicles: VehicleState;
  map_funcs: ExportedFuncs;
  onSearched: (
    address_to_address: AddressToAddressSearchResults | undefined,
    address_to_vehicle: AddressToVehicleSearchResults | undefined,
  ) => void;
  setSelectedVehicleId: (selection: VehicleSelection) => void;
  setShowState: React.Dispatch<React.SetStateAction<ShowState>>;
}

const Search: FC<SearchProps> = ({
  show,
  show_state,
  map_funcs,
  vehicles,
  onSearched,
  setSelectedVehicleId,
  setShowState,
}) => {
  const [search_type, setSearchType] = useState<SearchType>('none');

  useEffect(() => {
    map_funcs
      .unsetMarker('address_search_marker')
      .catch((e) => console.error(e));
  }, [search_type, map_funcs]);

  return !show ? null : (
    <div
      className={`SearchContainer ${search_type !== 'none' ? 'active' : ''}`}
    >
      <div className="SearchIcon">
        <Car />
      </div>
      <div className={`SearchButtonsContainer`}>
        <ButtonGroup fill>
          <SearchTypeButton
            current={search_type}
            type={'vehicle'}
            setType={setSearchType}
          />
          <SearchTypeButton
            current={search_type}
            type={'address'}
            setType={setSearchType}
          />
          <SearchTypeButton
            current={search_type}
            type={'address_to_vehicle'}
            setType={setSearchType}
          />
          <SearchTypeButton
            current={search_type}
            type={'address_to_address'}
            setType={setSearchType}
          />
        </ButtonGroup>
      </div>
      <div className="SearchInputsContainer">
        {search_type === 'address' && (
          <SingleInputSearch
            map_funcs={map_funcs}
            field_one="address"
            onClearSearch={() => onSearched(undefined, undefined)}
            vehicles={vehicles}
            onSearched={(address, pos) => {
              onSearched(undefined, undefined);
              console.log('Address search:', address, pos);
            }}
          />
        )}
        {search_type === 'vehicle' && (
          <SingleInputSearch
            map_funcs={map_funcs}
            field_one="vehicle"
            onClearSearch={() => onSearched(undefined, undefined)}
            vehicles={vehicles}
            onSearched={(id: string) => {
              const [vehicle_id, unit_id] = id.split('|');
              onSearched(undefined, undefined);
              setSelectedVehicleId({ vehicle_id, unit_id });
              setShowState({ ...show_state, vehicles: false });
            }}
          />
        )}
        {search_type === 'address_to_vehicle' && (
          <DualInputSearch
            map_funcs={map_funcs}
            field_one="address"
            field_two="vehicle"
            onClearSearch={() => onSearched(undefined, undefined)}
            onSearched={onSearched}
            vehicles={vehicles}
          />
        )}
        {search_type === 'address_to_address' && (
          <DualInputSearch
            map_funcs={map_funcs}
            field_one="address"
            field_two="address"
            onClearSearch={() => onSearched(undefined, undefined)}
            onSearched={onSearched}
            vehicles={vehicles}
          />
        )}
      </div>
    </div>
  );
};

export default Search;
