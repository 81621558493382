import VRedT from '../img/vehicle_marker/red-t.svg';
import VRedB from '../img/vehicle_marker/red-b.svg';
import VRedBB from '../img/vehicle_marker/red-bb.svg';
import VRedP from '../img/vehicle_marker/red-p.svg';

import VOrangeL from '../img/vehicle_marker/orange-l.svg';
import VOrangeT from '../img/vehicle_marker/orange-t.svg';
import VOrangeS from '../img/vehicle_marker/orange-s.svg';
import VOrangeP from '../img/vehicle_marker/orange-p.svg';

import VYellowL from '../img/vehicle_marker/yellow-l.svg';
import VYellowT from '../img/vehicle_marker/yellow-t.svg';
import VYellowS from '../img/vehicle_marker/yellow-s.svg';
import VYellowP from '../img/vehicle_marker/yellow-p.svg';

import VGreenL from '../img/vehicle_marker/green-l.svg';
import VGreenT from '../img/vehicle_marker/green-t.svg';
import VGreenS from '../img/vehicle_marker/green-s.svg';
import VGreenP from '../img/vehicle_marker/green-p.svg';

import VCyanL from '../img/vehicle_marker/cyan-l.svg';
import VCyanT from '../img/vehicle_marker/cyan-t.svg';
import VCyanS from '../img/vehicle_marker/cyan-s.svg';
import VCyanP from '../img/vehicle_marker/cyan-p.svg';

import VBlueL from '../img/vehicle_marker/blue-l.svg';
import VBlueT from '../img/vehicle_marker/blue-t.svg';
import VBlueS from '../img/vehicle_marker/blue-s.svg';
import VBlueP from '../img/vehicle_marker/blue-p.svg';

import VDarkBlueL from '../img/vehicle_marker/dark_blue-l.svg';
import VDarkBlueT from '../img/vehicle_marker/dark_blue-t.svg';
import VDarkBlueS from '../img/vehicle_marker/dark_blue-s.svg';
import VDarkBlueP from '../img/vehicle_marker/dark_blue-p.svg';

import VPurpleL from '../img/vehicle_marker/purple-l.svg';
import VPurpleT from '../img/vehicle_marker/purple-t.svg';
import VPurpleS from '../img/vehicle_marker/purple-s.svg';
import VPurpleP from '../img/vehicle_marker/purple-p.svg';

import VRoseL from '../img/vehicle_marker/rose-l.svg';
import VRoseT from '../img/vehicle_marker/rose-t.svg';
import VRoseS from '../img/vehicle_marker/rose-s.svg';
import VRoseP from '../img/vehicle_marker/rose-p.svg';

import VPinkL from '../img/vehicle_marker/pink-l.svg';
import VPinkT from '../img/vehicle_marker/pink-t.svg';
import VPinkS from '../img/vehicle_marker/pink-s.svg';
import VPinkP from '../img/vehicle_marker/pink-p.svg';

export const icon_by_type_and_color: {
  [color in GroupColor]: {
    [type in UnitType]: string;
  };
} = {
  red: {
    car: VRedP,
    minibus: VRedB,
    minibus_w_bed: VRedBB,
    stairclimber: VRedT,
  },
  orange: {
    car: VOrangeP,
    minibus: VOrangeS,
    minibus_w_bed: VOrangeL,
    stairclimber: VOrangeT,
  },
  yellow: {
    car: VYellowP,
    minibus: VYellowS,
    minibus_w_bed: VYellowL,
    stairclimber: VYellowT,
  },
  green: {
    car: VGreenP,
    minibus: VGreenS,
    minibus_w_bed: VGreenL,
    stairclimber: VGreenT,
  },
  cyan: {
    car: VCyanP,
    minibus: VCyanS,
    minibus_w_bed: VCyanL,
    stairclimber: VCyanT,
  },
  blue: {
    car: VBlueP,
    minibus: VBlueS,
    minibus_w_bed: VBlueL,
    stairclimber: VBlueT,
  },
  dark_blue: {
    car: VDarkBlueP,
    minibus: VDarkBlueS,
    minibus_w_bed: VDarkBlueL,
    stairclimber: VDarkBlueT,
  },
  purple: {
    car: VPurpleP,
    minibus: VPurpleS,
    minibus_w_bed: VPurpleL,
    stairclimber: VPurpleT,
  },
  rose: {
    car: VRoseP,
    minibus: VRoseS,
    minibus_w_bed: VRoseL,
    stairclimber: VRoseT,
  },
  pink: {
    car: VPinkP,
    minibus: VPinkS,
    minibus_w_bed: VPinkL,
    stairclimber: VPinkT,
  },
};
