import React, { FC, useState, useEffect } from 'react';
import { MenuItem, Menu } from '@blueprintjs/core';
import {
  Suggest as BPSuggest,
  ItemRenderer,
  ItemPredicate,
  ItemListRenderer,
} from '@blueprintjs/select';

interface VehicleSelectProps {
  vehicles: VehicleState;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  value: string;
  defaultValue: string;
}

const Suggest = BPSuggest.ofType<UnitInfo>();

const itemPredicate: ItemPredicate<UnitInfo> = (query, { unit_id }) => {
  return unit_id.startsWith(query);
};

const renderVehicle: ItemRenderer<UnitInfo> = (
  { unit_id },
  { handleClick },
) => {
  return <MenuItem key={unit_id} onClick={handleClick} text={unit_id} />;
};

const renderer: ItemListRenderer<UnitInfo> = (items) => {
  return (
    <Menu className="VehicleSelectMenu">
      {items.filteredItems.length > 0 ? (
        items.filteredItems.slice(0, 50).map(items.renderItem)
      ) : (
        <MenuItem disabled={true} text="Inga resultat" />
      )}
    </Menu>
  );
};

const inputValueRenderer: (T: UnitInfo) => string = (info) => info.unit_id;

const VehicleSelect: FC<VehicleSelectProps> = ({
  vehicles,
  onChange,
  value,
  defaultValue,
}) => {
  const [selected, setSelected] = useState<UnitInfo | null>(null);

  useEffect(() => {
    if (!value) {
      setSelected(null);
    }
  }, [value]);

  return (
    <>
      <Suggest
        onItemSelect={(v) => {
          setSelected(v);
          onChange(v.unit_id);
        }}
        query={value}
        onQueryChange={(v) => onChange(v)}
        fill
        items={vehicles.all}
        itemPredicate={itemPredicate}
        inputValueRenderer={inputValueRenderer}
        itemListRenderer={renderer}
        itemRenderer={renderVehicle}
        selectedItem={selected}
        inputProps={{
          large: true,
          leftIcon: 'known-vehicle',
          placeholder: defaultValue,
        }}
        popoverProps={{
          minimal: false,
          fill: true,
          usePortal: false,
          position: 'bottom',
        }}
      />
    </>
  );
};

export default VehicleSelect;
