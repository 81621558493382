import createOldApi from '../api/old';
import { Config } from '../config';

export const fetchVehicleDetails = async (
  unit_id: string,
  config: Config,
): Promise<FetchedVehicleDetails> => {
  const oldApi = createOldApi(config);
  const [trace, bookings] = await Promise.all([
    oldApi.getVehicleState(unit_id),
    oldApi.getBookingLegs(unit_id),
  ]);
  return Promise.resolve({
    trace: trace
      .map((avl_report) => avl_report.location)
      .slice(0, config.selected_vehicle_trace_steps),
    bookings: bookings.current_events,
  });
};

const areBookingSpacesEqual = (
  a: BookingSpace[],
  b: BookingSpace[],
): boolean => {
  for (let i = 0; i < a.length; i++) {
    const ai = a[i];
    const bi = b[i];

    if (!bi || !ai || ai.count !== bi.count || ai.type !== bi.type) {
      return false;
    }
  }
  return true;
};

export const hasBookingsChanged = (
  old_bookings: CurrentEvent[],
  new_bookings: CurrentEvent[],
): boolean => {
  return old_bookings.some((a, i) => {
    const b = new_bookings[i];
    if (!b) {
      return true;
    }
    return Object.entries(a).some(([key, value]) => {
      const typed_key = key as keyof CurrentEvent;
      if (typed_key === 'location') {
        return (
          a.location.lat !== b.location.lat || a.location.lng !== b.location.lng
        );
      } else if (
        typed_key === 'space_off' ||
        typed_key === 'space_on' ||
        typed_key === 'space_on_board'
      ) {
        return !areBookingSpacesEqual(a[typed_key], b[typed_key]);
      } else {
        return b[typed_key] !== value;
      }
    });
  });
};
