import createOldApi from '../api/old';
import { Config } from '../config';

export const fetchBusDetails = async (
  bus_id: string,
  config: Config,
): Promise<FetchedBusDetails> => {
  const oldApi = createOldApi(config);
  const [rt, avl_reports] = await Promise.all([
    oldApi.getBusInfo(bus_id),
    oldApi.getBusAVL(bus_id),
  ]);
  return Promise.resolve({
    id: bus_id,
    rt,
    trace: avl_reports
      .map((avl_report) => avl_report.location)
      .slice(0, config.selected_vehicle_trace_steps),
  });
};
