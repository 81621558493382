/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
//Disabled because I couldn't figure out how to fix it properly.

import axios, { AxiosRequestConfig, ResponseType } from 'axios';

declare global {
  interface Window {
    sso: any;
  }
}

interface LooseObject {
  [key: string]: any;
}

type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

type ApiCall = <T>(
  url: string,
  method?: Method,
  params?: LooseObject | null,
  data?: any,
  response_type?: ResponseType | null,
) => Promise<T>;

const apiCall: ApiCall = (
  url,
  method = 'GET',
  params = null,
  data = null,
  response_type = null,
) => {
  const stack = new Error().stack;

  const opts: AxiosRequestConfig = {
    url: url,
    method: method,
    params: params,
    data: data,
    responseType: response_type || 'json',
  };

  return axios(opts)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      err.stack = stack;
      err.network_error = true;
      throw err;
    });
};

export default apiCall;
