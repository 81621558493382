import { Config } from '../config';

export const getInitGarageState = (config: Config): GarageState => {
  if (config.mock_api) {
    const garages: Garage[] = [
      {
        id: 0,
        name: 'Dark Moor - Swan lake',
        description: '',
        location: { lat: 55.6253581, lng: 13.7040783 },
      },
      {
        id: 1,
        name: 'Rhapsody - The last winged unicorn',
        description: '',
        location: { lat: 55.6805492, lng: 13.1836685 },
      },
      {
        id: 2,
        name: 'Dropkick Murphys - Rose tattoo',
        description: '',
        location: { lat: 55.9046565, lng: 14.2633745 },
      },
    ];
    return {
      all: garages,
      by_id: garages.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {} as { [key: number]: Garage }),
    };
  } else if (config.garages) {
    const garages = config.garages.map((garage, index) => ({
      ...garage,
      ...{ id: index },
    }));
    return {
      all: garages,
      by_id: garages.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {} as { [key: number]: Garage }),
    };
  } else {
    return {
      all: [],
      by_id: {},
    };
  }
};
