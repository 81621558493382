import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import oh from 'output-helpers';
import '@blueprintjs/core/lib/css/blueprint.css';
import './reset.css';
import './global.css';
import { bt_dictionary, st_dictionary, Dictionary } from './text';
import MainPanel from './MainPanel';
import withConfig from 'with-config';

import config, { Config } from './config';

withConfig.setDefault(config);

// oh.setConfig({
//   lang: 'sv-SE',
//   fallback_language: 'en-US',
//   date_locale: 'sv-SE',
//   dictionaries: [st_dictionary],
// });
withConfig
  .get()
  .then((config: Config) => {
    let dict: Dictionary = st_dictionary as Dictionary;
    switch (config.service_name) {
      case 'ntd_ui_bt':
        dict = bt_dictionary as Dictionary;
    }

    document.title = config.document_title;

    oh.setConfig({
      lang: 'sv-SE',
      fallback_language: 'en-US',
      date_locale: 'sv-SE',
      dictionaries: [dict],
    });
  })
  .catch((err) => {
    throw err;
  });

ReactDOM.render(
  <Router>
    <div className="AppBase">
      <Route
        exact
        path={[
          '/',
          '/vehicles',
          '/disruptions',
          '/unscheduled',
          '/vehicle/:vehicle_id',
        ]}
        component={MainPanel}
      />
    </div>
  </Router>,
  document.getElementById('root'),
);
