let bt_groups_mnemonic: [number[], number[]] | null | undefined = undefined;
export const btGroups = (
  vehicles: UnitInfo[],
  recalc: boolean,
): [number[], number[]] | null => {
  if (bt_groups_mnemonic !== undefined && !recalc) {
    return bt_groups_mnemonic;
  }
  //Here we can create and mnemonically save the groups for bt.
  bt_groups_mnemonic = null;
  return bt_groups_mnemonic || null;
};

export const btLegendTypes = (): UnitType[] => {
  return ['minibus', 'minibus_w_bed', 'car'];
};
