import { Config } from '../config';
import {
  filterValidUnits as btFilterValidUnits,
  filterBusses as btFilterBusses,
} from './bt';
import {
  filterValidUnits as stFilterValidUnits,
  filterBusses as stFilterBusses,
} from './st';

export const filterValidUnits = (config: Config, u: UnitInfoRes): boolean => {
  switch (config.service_name) {
    case 'ntd_ui_skane':
      return stFilterValidUnits(u);
    case 'ntd_ui_bt':
      return btFilterValidUnits(u);
    default:
      throw new Error('Invalid service when filter getAllUnits response.');
  }
};
export const filterBusses = (config: Config, u: VehicleStateRes): boolean => {
  switch (config.service_name) {
    case 'ntd_ui_skane':
      return stFilterBusses(u);
    case 'ntd_ui_bt':
      return btFilterBusses(u);
    default:
      throw new Error('Invalid service when filtering busses.');
  }
};
