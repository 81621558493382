import React, { FC, useState, useEffect } from 'react';
import WrappedMapBase, {
  GMW_WrappedMarker,
  GMW_WrappedPolyline,
  GMW_LatLngLiteral,
} from 'google-maps-wrapper';
import MarkerClusterer from '@google/markerclustererplus';

import './MapStyle.css';

import {
  getVehicleIcon,
  getPathStyle,
  getDisruptionIcon,
  getEmptyIcon,
  getClusterIcon,
  getUnscheduledIcon,
  getTraceStyle,
  getWaypointIconAndLabel,
  getSearchResultMarkerIcon,
  getSearchResultPathStyle,
  getBusIcon,
  getBusTargetIcon,
  getGarageIcon,
} from './styles_and_markers';
import { getDirections } from './directions';
import withConfig from 'with-config';
import { Config } from '../config';

interface ExternalFuncs {
  setCenter: (latlng: GMW_LatLngLiteral) => void;
}
type OnMarkerClick = (type: MarkerType, id: string | number) => void;
interface Props {
  init_cb?: (map_funcs: ExportedFuncs, additional_funcs: ExternalFuncs) => void;
  show_vehicles: boolean;
  show_disruptions: boolean;
  show_unscheduled: boolean;
  show_busses: boolean;
  show_garages: boolean;
  selected_vehicle_details?: SelectedVehicleDetails;
  selected_bus_details?: FetchedBusDetails;
  selected_garage_details?: Garage;
  /** Garage state. */
  garages: Garage[];
  /** Bus state. */
  busses: VehicleInfo[];
  /** Unit state. */
  vehicles: UnitInfo[];
  /** Disruption state. */
  disruptions: Disruption[];
  /** Unscheduled state. */
  unscheduled: UnscheduledBooking[];
  onMarkerClick: OnMarkerClick;
  enabled_groups: Set<number>;
  enabled_types: Set<UnitType>;
  address_to_address_search_result?: AddressToAddressSearchResults;
  address_to_vehicle_search_result?: AddressToVehicleSearchResults;
  /** Callback when user clicks on an empty area of the map. */
  onMapClick: () => void;
}

interface WrappedMarkerDictionary {
  [id: string]: GMW_WrappedMarker;
}

interface SelectedVehicle {
  marker: GMW_WrappedMarker | null;
  /** The path between previous event (previous booking) and next event (current booking/event) */
  path: GMW_WrappedPolyline | null;
  /** The trace of the vehicle, this is "AVL Reports". A series of VehicleState-points. */
  trace: GMW_WrappedPolyline | null;
  wp_marker0?: GMW_WrappedMarker;
  wp_marker1?: GMW_WrappedMarker;
  wp_marker2?: GMW_WrappedMarker;
  wp_marker3?: GMW_WrappedMarker;
  wp_marker4?: GMW_WrappedMarker;
  wp_marker5?: GMW_WrappedMarker;
  wp_marker6?: GMW_WrappedMarker;
  wp_marker7?: GMW_WrappedMarker;
  wp_marker8?: GMW_WrappedMarker;
  wp_marker9?: GMW_WrappedMarker;
  wp_marker10?: GMW_WrappedMarker;
  /** FOR COMPARISON ONLY! This value should be used to check if bookings have changed
   * when selected_vehicle_details have updated. This is necessary because we do not want to trigger
   * address and routing requests with google unless the bookings have actually changed. */
  bookings_ref: CurrentEvent[];
}
interface SelectedBus {
  next_stop_marker: GMW_WrappedMarker | null;
  bus_marker: GMW_WrappedMarker | null;
  path: GMW_WrappedPolyline | null;
  /** The trace of the vehicle, this is "AVL Reports". A series of VehicleState-points. */
  trace: GMW_WrappedPolyline | null;
}
type WPMarkerKey = keyof Omit<
  SelectedVehicle,
  'bookings_ref' | 'marker' | 'path' | 'trace'
>;
interface SearchResultObjects {
  path: GMW_WrappedPolyline | null;
  marker_one: GMW_WrappedMarker | null;
  marker_two: GMW_WrappedMarker | null;
}

interface MarkerContainers {
  [id: string]: {
    [id: string]: GMW_WrappedMarker;
  };
}

/** This is where new markers are created before they are assigned state data. */
const DEFAULT_CREATION_POSITION: GMW_LatLngLiteral = { lat: 55.7, lng: 13.6 };

//-------------------------------------------------------
//-------------------------------------------------------
/////unscheduled LAYER
/////

const showUnscheduled = (
  map: ExportedFuncs,
  show: boolean,
  unscheduled_markers: WrappedMarkerDictionary,
  unscheduled: UnscheduledBooking[],
  marker_containers: MarkerContainers,
  onMarkerClick: OnMarkerClick,
): void => {
  updateUnscheduledMarkers(
    map,
    show,
    unscheduled_markers,
    unscheduled,
    marker_containers,
    onMarkerClick,
  );
};

const hideUnscheduled = (
  unscheduled_markers: WrappedMarkerDictionary,
): void => {
  Object.values(unscheduled_markers).forEach((marker) => {
    marker.hide();
  });
};

const updateUnscheduledMarkers = (
  map: ExportedFuncs,
  show: boolean,
  unscheduled_markers: WrappedMarkerDictionary,
  unscheduled: UnscheduledBooking[],
  marker_containers: MarkerContainers,
  onMarkerClick: OnMarkerClick,
): void => {
  if (!show) {
    //Early exit if we are not currently viewing disruptions.
    return;
  }
  const unupdated_ids = new Set(Object.keys(unscheduled_markers));
  unscheduled.forEach((booking) => {
    unupdated_ids.delete(booking.pickup.booking_id.toString());
    getMarker(
      map,
      'unscheduled',
      booking.pickup.booking_id.toString(),
      marker_containers,
      onMarkerClick,
    )
      .then((marker) => {
        marker.show();
        return marker.setOptions({
          default: {
            position: booking.pickup.location,
            icon: getUnscheduledIcon(),
          },
        });
      })
      .catch((err) => {
        throw err;
      });
  });
  //Any unupdated_ids that are left no longer exist in the given state, so we remove them.
  unupdated_ids.forEach((id) => {
    unscheduled_markers[id].remove();
    delete unscheduled_markers[id];
  });
};

/////
/////end unscheduled LAYER
//-------------------------------------------------------
//-------------------------------------------------------
/////DISRUPTION LAYER
/////

const showDisruptions = (
  map: ExportedFuncs,
  show: boolean,
  disruption_markers: WrappedMarkerDictionary,
  disruptions: Disruption[],
  marker_containers: MarkerContainers,
  onMarkerClick: OnMarkerClick,
): void => {
  updateDisruptionMarkers(
    map,
    show,
    disruption_markers,
    disruptions,
    marker_containers,
    onMarkerClick,
  );
};

const hideDisruptions = (disruption_markers: WrappedMarkerDictionary): void => {
  Object.values(disruption_markers).forEach((marker) => {
    marker.hide();
  });
};

const updateDisruptionMarkers = (
  map: ExportedFuncs,
  show: boolean,
  disruption_markers: WrappedMarkerDictionary,
  disruptions: Disruption[],
  marker_containers: MarkerContainers,
  onMarkerClick: OnMarkerClick,
): void => {
  if (!show) {
    //Early exit if we are not currently viewing disruptions.
    return;
  }
  const unupdated_ids = new Set(Object.keys(disruption_markers));
  disruptions.forEach((disruption) => {
    unupdated_ids.delete(disruption.id.toString());
    getMarker(
      map,
      'disruption',
      disruption.id.toString(),
      marker_containers,
      onMarkerClick,
    )
      .then((marker) => {
        marker.show();
        return marker.setOptions({
          default: {
            position: disruption.location,
            icon: getDisruptionIcon(disruption.type),
          },
        });
      })
      .catch((err) => {
        throw err;
      });
  });
  //Any unupdated_ids that are left no longer exist in the given state, so we remove them.
  unupdated_ids.forEach((id) => {
    disruption_markers[id].remove();
    delete disruption_markers[id];
  });
};

/////
/////END DISRUPTIONS LAYER
//-------------------------------------------------------
//-------------------------------------------------------
/////GARAGE LAYER
/////

const showGarages = (
  map: ExportedFuncs,
  show: boolean,
  garage_markers: WrappedMarkerDictionary,
  garages: Garage[],
  marker_containers: MarkerContainers,
  onMarkerClick: OnMarkerClick,
): void => {
  updateGarageMarkers(
    map,
    show,
    garage_markers,
    garages,
    marker_containers,
    onMarkerClick,
  );
};

const hideGarages = (garage_markers: WrappedMarkerDictionary): void => {
  Object.values(garage_markers).forEach((marker) => {
    marker.hide();
  });
};

const updateGarageMarkers = (
  map: ExportedFuncs,
  show: boolean,
  garage_markers: WrappedMarkerDictionary,
  garages: Garage[],
  marker_containers: MarkerContainers,
  onMarkerClick: OnMarkerClick,
): void => {
  if (!show) {
    //Early exit if we are not currently viewing disruptions.
    return;
  }
  const unupdated_ids = new Set(Object.keys(garage_markers));
  garages.forEach((garage) => {
    unupdated_ids.delete(garage.id.toString());
    getMarker(
      map,
      'garage',
      garage.id.toString(),
      marker_containers,
      onMarkerClick,
      undefined,
      undefined,
      garage.id,
    )
      .then((marker) => {
        marker.show();
        return marker.setOptions({
          default: {
            position: garage.location,
            icon: getGarageIcon(),
            label: {
              text: garage.name,
              color: '#000',
              fontSize: '10px',
              fontWeight: 'bold',
            },
          },
          selected: {
            position: garage.location,
            icon: getGarageIcon(true),
          },
        });
      })
      .catch((err) => {
        throw err;
      });
  });
  //Any unupdated_ids that are left no longer exist in the given state, so we remove them.
  unupdated_ids.forEach((id) => {
    garage_markers[id].remove();
    delete garage_markers[id];
  });
};

/////
/////END GARAGE LAYER
//-------------------------------------------------------
//-------------------------------------------------------
/////SELECTED LAYER
/////

const getSelectedBus = (
  map: ExportedFuncs,
  selected_bus: SelectedBus,
  config: Config,
  bus_id: string,
  marker_containers: MarkerContainers,
): Promise<SelectedBus> => {
  //Create new marker and path if necessary.
  const next_stop_marker_promise =
    selected_bus.next_stop_marker ||
    map.setMarker('next_bus_stop_marker', {
      default: {
        visible: false,
        position: DEFAULT_CREATION_POSITION,
        icon: getEmptyIcon(),
        label: {
          text: '',
        },
      },
    });
  // const path_promise =
  //   selected_bus.path ||
  //   map.setPolyline('selected_bus_path', {
  //     default: {
  //       ...getPathStyle(),
  //       path: [DEFAULT_CREATION_POSITION, DEFAULT_CREATION_POSITION],
  //     },
  //   });
  const trace_promise =
    selected_bus.trace ||
    map.setPolyline('selected_bus_trace', {
      default: {
        ...getTraceStyle(0, config),
        path: [DEFAULT_CREATION_POSITION, DEFAULT_CREATION_POSITION],
      },
    });

  // const bus_marker_promise = getMarker(
  //     map,
  //     'bus',
  //     vehicle.unit_id,
  //     marker_containers,
  //     onMarkerClick,
  //     undefined,
  //     undefined,
  //     'bus|' + vehicle.unit_id,
  //   ).then((marker) => {

  //   });
  const bus_marker = Object.prototype.hasOwnProperty.call(
    marker_containers.bus_markers,
    bus_id,
  )
    ? marker_containers.bus_markers[bus_id]
    : null;

  return Promise.all([
    next_stop_marker_promise,
    trace_promise,
    bus_marker,
  ]).then(([next_stop_marker, trace_poly, bus_marker]) => {
    selected_bus.next_stop_marker = next_stop_marker;
    selected_bus.bus_marker = bus_marker;
    selected_bus.trace = trace_poly;
    return selected_bus;
  });
};

const selectBus = async (
  map: ExportedFuncs,
  existing_selected_bus: SelectedBus,
  details: FetchedBusDetails,
  config: Config,
  marker_containers: MarkerContainers,
): Promise<void> => {
  const { trace, rt } = details;
  if (existing_selected_bus) {
    existing_selected_bus.bus_marker?.applyOptions('default');
  }
  const selected_vehicle = await getSelectedBus(
    map,
    existing_selected_bus,
    config,
    details.id,
    marker_containers,
  );

  const {
    bus_marker: bus_marker_obj,
    next_stop_marker: next_stop_marker_obj,
    trace: trace_obj,
  } = selected_vehicle;

  bus_marker_obj?.applyOptions('selected');

  //Update next_stop marker.
  //TODO: We do not have a location for the target bus stop. For now we just never show the icon.
  // marker_obj && marker_obj.show();
  next_stop_marker_obj
    ?.setOptions({
      default: {
        visible: false,
        position: rt.location,
        icon: getBusTargetIcon(),
        label: {
          text: rt.NextHplName,
          color: '#000',
          fontSize: '12px',
          fontWeight: 'bold',
        },
      },
    })
    .catch((err) => {
      throw err;
    });

  trace_obj?.show();
  trace_obj
    ?.setOptions({
      default: {
        path: trace,
        ...getTraceStyle(0, config),
      },
    })
    .catch((err) => {
      throw err;
    });
};
const deselectBus = (selected_bus: SelectedBus): void => {
  const { next_stop_marker, path, trace, bus_marker } = selected_bus;
  bus_marker?.applyOptions('default');
  next_stop_marker?.hide();
  path?.hide();
  trace?.hide();
};

const getSelectedVehicle = (
  map: ExportedFuncs,
  selected_vehicle: SelectedVehicle,
  group: number,
  config: Config,
): Promise<SelectedVehicle> => {
  //Create new marker and path if necessary.
  const vehicle_marker_promise =
    selected_vehicle.marker ||
    map.setMarker('selected_vehicle', {
      default: {
        visible: false,
        position: DEFAULT_CREATION_POSITION,
        icon: getEmptyIcon(),
        label: {
          text: '',
        },
      },
    });
  const wp_markers = Array(10)
    .fill(undefined)
    .map((v, i) => {
      const id = ('wp_marker' + i) as keyof SelectedVehicle;
      return (
        (selected_vehicle[id] as GMW_WrappedMarker) ||
        map.setMarker('selected_vehicle_wp_marker' + i, {
          default: {
            visible: false,
            position: DEFAULT_CREATION_POSITION,
            icon: getEmptyIcon(),
          },
        })
      );
    });
  const path_promise =
    selected_vehicle.path ||
    map.setPolyline('selected_vehicle_path', {
      default: {
        ...getPathStyle(),
        path: [DEFAULT_CREATION_POSITION, DEFAULT_CREATION_POSITION],
      },
    });
  const trace_promise =
    selected_vehicle.trace ||
    map.setPolyline('selected_vehicle_trace', {
      default: {
        ...getTraceStyle(group, config),
        path: [DEFAULT_CREATION_POSITION, DEFAULT_CREATION_POSITION],
      },
    });

  return Promise.all([vehicle_marker_promise, path_promise, trace_promise])
    .then(([vehicle_marker, path_poly, trace_poly]) => {
      selected_vehicle.marker = vehicle_marker;
      selected_vehicle.path = path_poly;
      selected_vehicle.trace = trace_poly;
      return Promise.all([...wp_markers]);
    })
    .then((wp_markers) => {
      wp_markers.forEach((wp, i) => {
        const id = ('wp_marker' + i) as WPMarkerKey;
        selected_vehicle[id] = wp;
      });
      return selected_vehicle;
    });
};

const selectVehicle = async (
  map: ExportedFuncs,
  existing_selected_vehicle: SelectedVehicle,
  details: SelectedVehicleDetails,
  config: Config,
): Promise<void> => {
  const { bookings, trace, vehicle } = details;
  const selected_vehicle = await getSelectedVehicle(
    map,
    existing_selected_vehicle,
    vehicle.group,
    config,
  );

  const {
    marker: marker_obj,
    trace: trace_obj,
    path: path_obj,
  } = selected_vehicle;

  //Update marker.
  marker_obj && marker_obj.show();
  marker_obj &&
    marker_obj
      .setOptions({
        default: {
          position: vehicle.location,
          icon: getVehicleIcon(vehicle.type, vehicle.group, config),
          label: {
            text: vehicle.unit_id,
            color: '#fff',
            fontSize: '14px',
            fontWeight: 'bold',
          },
        },
      })
      .catch((err) => {
        throw err;
      });
  path_obj && path_obj.show();

  trace_obj && trace_obj.show();
  trace_obj &&
    trace_obj
      .setOptions({
        default: {
          path: trace,
          ...getTraceStyle(vehicle.group, config),
        },
      })
      .catch((err) => {
        throw err;
      });

  if (selected_vehicle.bookings_ref !== bookings) {
    selected_vehicle.bookings_ref = bookings;
    path_obj && path_obj.hide();

    //The bookings data have changed. We need to resolve a new route from google.
    const directions: ResolvedDirections =
      bookings && bookings.length > 1
        ? await getDirections(
            map,
            bookings.map((ce) => ce.location),
          )
        : { path: [] };

    const { path, bounds } = directions;

    if (bounds) {
      map.setBounds(bounds).catch((err) => {
        console.error(err);
        return;
      });
    }

    path_obj && path_obj.show();
    path_obj &&
      path_obj
        .setOptions({
          default: {
            path: path,
          },
        })
        .catch((err) => {
          throw err;
        });

    const index_by_id: { [id: string]: number } = {};
    let color_idx = 0;

    for (let i = 0; i < 10; i++) {
      const id = ('wp_marker' + i) as WPMarkerKey;
      const marker = selected_vehicle[id];
      if (!marker) {
        continue;
      }
      const booking = bookings[i];
      if (booking) {
        if (
          !Object.prototype.hasOwnProperty.call(index_by_id, booking.booking_id)
        ) {
          index_by_id[booking.booking_id] = color_idx++;
        }
        marker.show();
        marker
          .setOptions({
            default: {
              position: booking.location,
              ...getWaypointIconAndLabel(
                vehicle,
                index_by_id[booking.booking_id],
                i,
              ),
            },
          })
          .catch((err) => {
            throw err;
          });
      } else {
        marker.hide();
      }
    }
  }
};

const deselectVehicle = (selected_vehicle: SelectedVehicle): void => {
  const { marker, path, trace } = selected_vehicle;
  marker && marker.hide();
  path && path.hide();
  trace && trace.hide();
  for (let i = 0; i <= 10; i++) {
    const id = ('wp_marker' + i) as WPMarkerKey;
    const marker = selected_vehicle[id];
    marker && marker.hide();
  }
};

const selectGarage = async (
  map: ExportedFuncs,
  garage: Garage,
  marker_containers: MarkerContainers,
  onMarkerClick: OnMarkerClick,
): Promise<void> => {
  deselectGarage(marker_containers);
  const marker_obj = await getMarker(
    map,
    'garage',
    garage.id.toString(),
    marker_containers,
    onMarkerClick,
    undefined,
    undefined,
    garage.id,
  );

  //Update marker.
  marker_obj && marker_obj.show();
  void marker_obj.applyOptions('selected');
};

const deselectGarage = (marker_containers: MarkerContainers): void => {
  Object.values(marker_containers['garage_markers']).forEach(
    (garage_marker) => {
      garage_marker.applyOptions('default');
    },
  );
};

/////
/////END SELECTED LAYER
//-------------------------------------------------------
//-------------------------------------------------------
/////VEHICLES LAYER
/////

const hideVehicles = (
  vehicle_markers: WrappedMarkerDictionary,
  clusterers: MarkerClusterer[],
): void => {
  Object.values(vehicle_markers).forEach((marker) => {
    marker.hide();
  });
  clusterers.forEach((clusterer) => {
    clusterer.repaint();
  });
};

const showVehicles = (
  map: ExportedFuncs,
  show: boolean,
  vehicles: UnitInfo[],
  vehicle_markers: WrappedMarkerDictionary,
  marker_containers: MarkerContainers,
  onMarkerClick: OnMarkerClick,
  clusterers: MarkerClusterer[],
  enabled_groups: Set<number>,
  enabled_types: Set<UnitType>,
  config: Config,
): void => {
  updateVehicleMarkers(
    map,
    show,
    vehicles,
    vehicle_markers,
    marker_containers,
    onMarkerClick,
    clusterers,
    enabled_groups,
    enabled_types,
    config,
  );
};

const updateVehicleMarkers = (
  map: ExportedFuncs,
  show: boolean,
  vehicles: UnitInfo[],
  vehicle_markers: WrappedMarkerDictionary,
  marker_containers: MarkerContainers,
  onMarkerClick: OnMarkerClick,
  clusterers: MarkerClusterer[],
  enabled_groups: Set<number>,
  enabled_types: Set<UnitType>,
  config: Config,
): void => {
  if (!show) {
    //Early exit if we are not currently viewing vehicles.
    return;
  }
  const unupdated_ids = new Set(Object.keys(vehicle_markers));
  const promises = vehicles.map((vehicle) => {
    unupdated_ids.delete(vehicle.vehicle_id);
    return getMarker(
      map,
      'vehicle',
      vehicle.vehicle_id,
      marker_containers,
      onMarkerClick,
      clusterers,
      vehicle.group,
      vehicle.vehicle_id + '|' + vehicle.unit_id,
    ).then((marker) => {
      if (
        enabled_groups.has(vehicle.group) &&
        enabled_types.has(vehicle.type)
      ) {
        marker.show();
      } else {
        marker.hide();
      }
      return marker.setOptions({
        default: {
          position: vehicle.location,
          icon: getVehicleIcon(vehicle.type, vehicle.group, config),
          label: {
            text: vehicle.unit_id,
            color: '#fff',
            fontSize: '14px',
            fontWeight: 'bold',
          },
        },
      });
    });
  });

  void Promise.all(promises).then(() => {
    //Any unupdated_ids that are left no longer exist in the given state, so we remove them.
    unupdated_ids.forEach((id) => {
      console.log('id:', id);
      clusterers.forEach((c) =>
        c.removeMarker(vehicle_markers[id].gmaps_obj, true),
      );
      vehicle_markers[id].remove();
      delete vehicle_markers[id];
    });

    clusterers.forEach((clusterer) => {
      clusterer.repaint();
    });
  });
};
/////
/////END VEHICLES LAYER
//-------------------------------------------------------
//-------------------------------------------------------
/////BUS LAYER
/////

const hideBusses = (bus_markers: WrappedMarkerDictionary): void => {
  Object.values(bus_markers).forEach((marker) => {
    marker.hide();
  });
};

const showBusses = (
  map: ExportedFuncs,
  show: boolean,
  busses: VehicleInfo[],
  bus_markers: WrappedMarkerDictionary,
  marker_containers: MarkerContainers,
  onMarkerClick: OnMarkerClick,
): void => {
  updateBusMarkers(
    map,
    show,
    busses,
    bus_markers,
    marker_containers,
    onMarkerClick,
  );
};

const updateBusMarkers = (
  map: ExportedFuncs,
  show: boolean,
  busses: VehicleInfo[],
  bus_markers: WrappedMarkerDictionary,
  marker_containers: MarkerContainers,
  onMarkerClick: OnMarkerClick,
): void => {
  if (!show) {
    //Early exit if we are not currently viewing vehicles.
    return;
  }
  const unupdated_ids = new Set(Object.keys(bus_markers));
  const promises = busses.map((vehicle) => {
    unupdated_ids.delete(vehicle.unit_id);
    return getMarker(
      map,
      'bus',
      vehicle.unit_id,
      marker_containers,
      onMarkerClick,
      undefined,
      undefined,
      'bus|' + vehicle.unit_id,
    ).then((marker) => {
      marker.show();
      return marker.setOptions({
        default: {
          position: vehicle.location,
          icon: getBusIcon(),
          label: {
            text: vehicle.line_nr.toString(),
            color: '#000',
            fontSize: '10px',
            fontWeight: 'bold',
          },
        },
        selected: {
          position: vehicle.location,
          icon: getBusIcon(true),
        },
      });
    });
  });

  void Promise.all(promises).then(() => {
    //Any unupdated_ids that are left no longer exist in the given state, so we remove them.
    unupdated_ids.forEach((id) => {
      bus_markers[id].remove();
      delete bus_markers[id];
    });
  });
};
/////
/////END BUS LAYER
//-------------------------------------------------------
//-------------------------------------------------------
/////SEARCH RESULTS LAYER
/////

const updateSearchResults = async (
  map: ExportedFuncs,
  search_result_objects: SearchResultObjects,
  a_to_a: AddressToAddressSearchResults | undefined,
  a_to_v: AddressToVehicleSearchResults | undefined,
): Promise<void> => {
  //Create markers if necessary.
  search_result_objects.marker_one =
    search_result_objects.marker_one ||
    (await map.setMarker('search_result_marker_one', {
      default: {
        visible: false,
        position: DEFAULT_CREATION_POSITION,
        icon: getSearchResultMarkerIcon(),
      },
    }));
  search_result_objects.marker_two =
    search_result_objects.marker_two ||
    (await map.setMarker('search_result_marker_two', {
      default: {
        visible: false,
        position: DEFAULT_CREATION_POSITION,
        icon: getSearchResultMarkerIcon(),
      },
    }));
  search_result_objects.path =
    search_result_objects.path ||
    (await map.setPolyline('search_result_path', {
      default: {
        ...getSearchResultPathStyle(),
        visible: false,
        path: [DEFAULT_CREATION_POSITION, DEFAULT_CREATION_POSITION],
      },
    }));

  const { marker_one, marker_two, path } = search_result_objects;

  if (!a_to_a && !a_to_v) {
    //We have no search results at all to show, hide all.
    marker_one.hide();
    marker_two.hide();
    path.hide();
  }

  const pos_one = (a_to_a && a_to_a.poi_one) || (a_to_v && a_to_v.address_pos);
  const address_one =
    (a_to_a && a_to_a.address_one) || (a_to_v && a_to_v.address_label);
  const label_one = address_one && {
    text: address_one,
    color: '#000', //TODO: Fix the label style when icon corrected.
    fontSize: '12px',
    fontWeight: 'bold',
  };

  if (!pos_one) {
    marker_one.hide();
  } else {
    marker_one.show();
    marker_one
      .setOptions({
        default: {
          position: pos_one,
          icon: getSearchResultMarkerIcon(),
          label: label_one,
        },
      })
      .catch((err) => {
        throw err;
      });
  }

  const pos_two = a_to_a && a_to_a.poi_two;
  const address_two = a_to_a && a_to_a.address_two;
  const label_two = address_two && {
    text: address_two,
    color: '#000', //TODO: Fix the label style when icon corrected.
    fontSize: '12px',
    fontWeight: 'bold',
  };

  if (!pos_two) {
    marker_two.hide();
  } else {
    marker_two.show();
    marker_two
      .setOptions({
        default: {
          position: pos_two,
          icon: getSearchResultMarkerIcon(),
          label: label_two,
        },
      })
      .catch((err) => {
        throw err;
      });
  }

  const new_path_points = (a_to_a && a_to_a.path) || (a_to_v && a_to_v.path);
  if (!new_path_points) {
    path.hide();
  } else {
    path.show();
    path
      .setOptions({
        default: {
          path: new_path_points,
        },
      })
      .catch((err) => {
        throw err;
      });
  }
};

/////
/////END SEARCH RESULTS
//-------------------------------------------------------
//-------------------------------------------------------
/////OTHER FUNCS
/////

const getMarker = async (
  map: ExportedFuncs,
  type: MarkerType,
  id: string,
  marker_containers: MarkerContainers,
  onMarkerClick: OnMarkerClick,
  clusterers?: MarkerClusterer[],
  group?: number,
  id_for_click?: string | number,
): Promise<GMW_WrappedMarker> => {
  const container = marker_containers[type + '_markers'];
  if (container[id]) {
    return container[id];
  }
  if (type === 'bus' || type === 'garage') {
    container[id] = await map.setMarker(type + '_' + id, {
      default: {
        visible: false,
        position: DEFAULT_CREATION_POSITION,
        icon: getEmptyIcon(),
      },
      selected: {
        position: DEFAULT_CREATION_POSITION,
        icon: getEmptyIcon(),
      },
    });
  } else {
    container[id] = await map.setMarker(type + '_' + id, {
      default: {
        visible: false,
        position: DEFAULT_CREATION_POSITION,
        icon: getEmptyIcon(),
      },
    });
  }
  if (type === 'vehicle' && group !== undefined && clusterers && id_for_click) {
    clusterers[group].addMarker(container[id].gmaps_obj, true);
    container[id].registerEventCB('click', () => {
      onMarkerClick('vehicle', id_for_click);
    });
  }
  if (type === 'disruption') {
    container[id].registerEventCB('click', () => {
      onMarkerClick('disruption', parseInt(id, 10));
    });
  }
  if (type === 'bus') {
    container[id].registerEventCB('click', () => {
      onMarkerClick('bus', id);
    });
  }
  if (type === 'unscheduled') {
    container[id].registerEventCB('click', () => {
      onMarkerClick('unscheduled', parseInt(id, 10));
    });
  }
  if (type === 'garage' && typeof id_for_click === 'number') {
    container[id].registerEventCB('click', () => {
      onMarkerClick('garage', id_for_click);
    });
  }

  return container[id];
};

const onMapInitialized = (
  map: ExportedFuncs,
  setWrappedMap: React.Dispatch<React.SetStateAction<ExportedFuncs | null>>,
  setClusterers: React.Dispatch<React.SetStateAction<MarkerClusterer[]>>,
  config: Config,
  init_cb?: (map_funcs: ExportedFuncs, additional_funcs: ExternalFuncs) => void,
): void => {
  setWrappedMap(map);

  const clusterer_promises = Array(9)
    .fill(undefined)
    .map((v, group) => {
      return map.setClusterer({
        gridSize: config.cluster_size,
        minimumClusterSize: config.cluster_min_size,
        maxZoom: config.cluster_max_zoom,
        averageCenter: true,
        ignoreHidden: true,
        batchSize: 5000,
        styles: [
          map.createClustererStyle({
            url: getClusterIcon(group, config),
            width: 64,
            height: 64,
            anchorIcon: [32, 32],
            textSize: 16,
            textColor: '#fff',
          }),
        ],
      });
    });
  Promise.all(clusterer_promises)
    .then((clusterers) => {
      setClusterers(clusterers);
    })
    .catch((err) => {
      throw err;
    });

  init_cb &&
    init_cb(map, {
      setCenter: (latlng) => setCenter(map, latlng),
    });
};

const setCenter = (map: ExportedFuncs, latlng: GMW_LatLngLiteral): void => {
  map.setCenter(latlng).catch((err) => {
    throw err;
  });
};

/////////////////////////////
/////////////////////////////

const Map: FC<Props> = ({
  disruptions,
  init_cb,
  onMarkerClick,
  selected_vehicle_details,
  selected_bus_details,
  selected_garage_details,
  unscheduled,
  show_disruptions,
  show_unscheduled,
  show_vehicles,
  show_busses,
  show_garages,
  garages,
  vehicles,
  busses,
  enabled_groups,
  enabled_types,
  address_to_address_search_result,
  address_to_vehicle_search_result,
  onMapClick,
}) => {
  const config = withConfig.getCurrentConfig() as Config;

  const [wrapped_map, setWrappedMap] = useState<ExportedFuncs | null>(null);
  const [clusterers, setClusterers] = useState<MarkerClusterer[]>([]);
  const [vehicle_markers] = useState<WrappedMarkerDictionary>({});
  const [bus_markers] = useState<WrappedMarkerDictionary>({});
  const [disruption_markers] = useState<WrappedMarkerDictionary>({});
  const [unscheduled_markers] = useState<WrappedMarkerDictionary>({});
  const [garage_markers] = useState<WrappedMarkerDictionary>({});
  const [marker_containers] = useState<MarkerContainers>({
    vehicle_markers,
    disruption_markers,
    unscheduled_markers,
    bus_markers,
    garage_markers,
  });
  const [selected_vehicle] = useState<SelectedVehicle>({
    marker: null,
    path: null,
    trace: null,
    bookings_ref: [],
  });
  const [selected_bus] = useState<SelectedBus>({
    bus_marker: null,
    next_stop_marker: null,
    path: null,
    trace: null,
  });
  const [search_result_objects] = useState<SearchResultObjects>({
    path: null,
    marker_one: null,
    marker_two: null,
  });

  useEffect(() => {
    if (!wrapped_map || clusterers.length === 0) {
      //This useEffect will run once wrapped_map and clusterers are set.
      return;
    }
    if (selected_vehicle_details && selected_vehicle) {
      selectVehicle(
        wrapped_map,
        selected_vehicle,
        selected_vehicle_details,
        config,
      ).catch((err) => {
        throw err;
      });
    }
    if (selected_bus_details && selected_bus) {
      selectBus(
        wrapped_map,
        selected_bus,
        selected_bus_details,
        config,
        marker_containers,
      ).catch((err) => {
        throw err;
      });
    }
    if (show_vehicles) {
      showVehicles(
        wrapped_map,
        show_vehicles,
        vehicles,
        vehicle_markers,
        marker_containers,
        onMarkerClick,
        clusterers,
        enabled_groups,
        enabled_types,
        config,
      );
    } else {
      hideVehicles(vehicle_markers, clusterers);
    }
    if (show_busses) {
      showBusses(
        wrapped_map,
        show_busses,
        busses,
        bus_markers,
        marker_containers,
        onMarkerClick,
      );
    } else {
      hideBusses(bus_markers);
    }
    if (show_disruptions) {
      showDisruptions(
        wrapped_map,
        show_disruptions,
        disruption_markers,
        disruptions,
        marker_containers,
        onMarkerClick,
      );
    } else {
      hideDisruptions(disruption_markers);
    }
    if (show_garages) {
      showGarages(
        wrapped_map,
        show_garages,
        garage_markers,
        garages,
        marker_containers,
        onMarkerClick,
      );
    } else {
      hideGarages(garage_markers);
    }
    if (show_unscheduled) {
      showUnscheduled(
        wrapped_map,
        show_unscheduled,
        unscheduled_markers,
        unscheduled,
        marker_containers,
        onMarkerClick,
      );
    } else {
      hideUnscheduled(unscheduled_markers);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    show_unscheduled,
    show_disruptions,
    show_vehicles,
    show_busses,
    show_garages,
    wrapped_map,
    clusterers,
  ]);

  useEffect(() => {
    if (!wrapped_map || clusterers.length === 0) {
      return;
    }
    updateDisruptionMarkers(
      wrapped_map,
      show_disruptions,
      disruption_markers,
      disruptions,
      marker_containers,
      onMarkerClick,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disruptions]);
  useEffect(() => {
    if (!wrapped_map || clusterers.length === 0) {
      return;
    }
    updateUnscheduledMarkers(
      wrapped_map,
      show_unscheduled,
      unscheduled_markers,
      unscheduled,
      marker_containers,
      onMarkerClick,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unscheduled, wrapped_map, clusterers]);
  useEffect(() => {
    if (!wrapped_map || clusterers.length === 0) {
      return;
    }
    updateVehicleMarkers(
      wrapped_map,
      show_vehicles,
      vehicles,
      vehicle_markers,
      marker_containers,
      onMarkerClick,
      clusterers,
      enabled_groups,
      enabled_types,
      config,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles, enabled_groups, enabled_types, wrapped_map, clusterers]);
  useEffect(() => {
    if (!wrapped_map || clusterers.length === 0) {
      return;
    }
    updateBusMarkers(
      wrapped_map,
      show_busses,
      busses,
      bus_markers,
      marker_containers,
      onMarkerClick,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [busses, wrapped_map, clusterers]);
  useEffect(() => {
    if (!wrapped_map) {
      return;
    }
    if (selected_vehicle_details) {
      selectVehicle(
        wrapped_map,
        selected_vehicle,
        selected_vehicle_details,
        config,
      ).catch((err) => {
        throw err;
      });
    } else {
      deselectVehicle(selected_vehicle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_vehicle_details, wrapped_map]);
  useEffect(() => {
    if (!wrapped_map) {
      return;
    }
    if (selected_garage_details) {
      selectGarage(
        wrapped_map,
        selected_garage_details,
        marker_containers,
        onMarkerClick,
      ).catch((err) => {
        throw err;
      });
    } else {
      deselectGarage(marker_containers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_garage_details, wrapped_map]);
  useEffect(() => {
    if (!wrapped_map) {
      return;
    }

    if (selected_bus_details) {
      selectBus(
        wrapped_map,
        selected_bus,
        selected_bus_details,
        config,
        marker_containers,
      ).catch((err) => {
        throw err;
      });
    } else {
      deselectBus(selected_bus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_bus_details, marker_containers, wrapped_map]);
  useEffect(() => {
    if (!wrapped_map) {
      return;
    }
    updateSearchResults(
      wrapped_map,
      search_result_objects,
      address_to_address_search_result,
      address_to_vehicle_search_result,
    ).catch((err) => {
      throw err;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    address_to_vehicle_search_result,
    address_to_address_search_result,
    wrapped_map,
  ]);

  /////
  /////end USE EFFECTS

  return (
    <div className="MapContainer">
      <WrappedMapBase
        googleapi_maps_uri={config.api.google.maps_uri}
        default_center={config.map.center}
        default_zoom={config.map.zoom}
        default_options={{
          mapTypeControlOptions: {
            position: 3,
          },
        }}
        initializedCB={(map_ref, funcs) => {
          onMapInitialized(
            funcs,
            setWrappedMap,
            setClusterers,
            config,
            init_cb,
          );
        }}
        styles={config.map.style}
        onClick={() => {
          onMapClick();
        }}
      />
    </div>
  );
};

export default Map;
