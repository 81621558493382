import apiCall from './api_call';
import { Config } from '../config';
import {
  convertVehicleState,
  convertBookingLegs,
  convertUnitInfo,
  convertUnplannedBookings,
  convertRealTimeUnit,
} from './converters';
import {
  initMocks,
  isMocksInitialized,
  mockGetAllUnits,
  mockGetBookingLegs,
  mockGetVehicleState,
  mockGetUnbooked,
  mockGetAVLState,
  mockGetRealTimeUnits,
  mockGetBusState,
} from './mock_api_funcs';
import { filterValidUnits, filterBusses } from './res_filter';

interface OldApi {
  getAVLState: () => Promise<VehicleInfo[]>;
  getBusInfo: (bus_id: string) => Promise<RealTimeUnit>;
  getVehicleState: (vehicle_id: string) => Promise<VehicleInfo[]>;
  getBusAVL: (bus_id: string) => Promise<VehicleInfo[]>;
  getBookingLegs: (vehicle_id: string) => Promise<BookingLegs>;
  getAllUnits: () => Promise<UnitInfo[]>;
  getUnscheduledBookings: () => Promise<UnscheduledBooking[]>;
}

type CreateOldApiFunc = (config: Config) => OldApi;

const getStaticApi = (config: Config): OldApi => {
  if (config.mock_api && !isMocksInitialized()) {
    initMocks(config, 20);
  }

  return {
    getAVLState: () => {
      const req = config.mock_api
        ? Promise.resolve(mockGetAVLState())
        : apiCall<GetVehicleStateResponse>(
            config.api.avl.uri +
              '/GetAVLState?maxMinutesOld=5&maxNumVehicles=1000&_t=' +
              new Date().getTime(),
          );

      return req.then((res) => {
        return res.AVLReports.filter((b) => filterBusses(config, b)).map(
          convertVehicleState,
        );
      });
    },
    getBusInfo: (bus_id) => {
      const bus_id_numbers = bus_id.substr(3);
      if (!config.mock_api && !config.api.core) {
        throw new Error(
          'Tried to get bus info without specifying core url in config.',
        );
      }

      const req =
        config.mock_api || !config.api.core
          ? Promise.resolve(mockGetRealTimeUnits(bus_id_numbers))
          : apiCall<GetRealTimeUnitsResponse[]>(
              config.api.core.uri +
                '/GetRealTimeUnits/?_t=' +
                new Date().getTime(),
            );

      return req.then((res) => {
        const bus_realtime = res.find((r) => r.UnitId === bus_id_numbers);
        if (!bus_realtime) {
          throw new Error(
            'Unable to find the requested bus_id in realtime responses.',
          );
        }
        return convertRealTimeUnit(bus_realtime);
      });
    },
    getBusAVL: (bus_id) => {
      const req = config.mock_api
        ? Promise.resolve(mockGetBusState(bus_id))
        : apiCall<GetVehicleStateResponse>(
            config.api.avl.uri + '/GetVehicleState?vehicleId=' + bus_id,
          );

      return req.then((res) => {
        return res.AVLReports.map(convertVehicleState);
      });
    },
    getVehicleState: (unit_id) => {
      const req = config.mock_api
        ? Promise.resolve(mockGetVehicleState(unit_id))
        : apiCall<GetVehicleStateResponse>(
            config.api.avl.uri + '/GetVehicleState?vehicleId=PASS' + unit_id,
          );

      return req.then((res) => {
        return res.AVLReports.map(convertVehicleState);
      });
    },
    getBookingLegs: (unit_id: string) => {
      const req = config.mock_api
        ? Promise.resolve(mockGetBookingLegs(unit_id))
        : apiCall<GetBookingLegsResponse>(
            config.api.pass.uri + '/GetBookingLegs?unitId=' + unit_id,
          );

      return req.then((res) => {
        return convertBookingLegs(res);
      });
    },
    getUnscheduledBookings: () => {
      const req = config.mock_api
        ? Promise.resolve(mockGetUnbooked())
        : apiCall<GetUnplannedBookingResponse>(
            config.api.pass.uri + '/GetUnplannedBookings',
          );

      return req.then((res) => {
        return convertUnplannedBookings(res);
      });
    },
    getAllUnits: () => {
      const req = config.mock_api
        ? Promise.resolve(mockGetAllUnits())
        : apiCall<GetAllUnitsResponse>(config.api.pass.uri + '/GetAllUnits');

      return req.then((res) => {
        return res
          .filter((u) => filterValidUnits(config, u))
          .map((u) => convertUnitInfo(config, u));
      });
    },
  };
};

const createOldApi: CreateOldApiFunc = (config) => getStaticApi(config);

export default createOldApi;
