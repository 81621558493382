import React, { FC, SVGProps } from 'react';
import { Position, Tooltip } from '@blueprintjs/core';

import Booking from '../Booking';

import './VehicleDetailsBubbleStyle.css';

import text from '../text';
import { ReactComponent as Human } from '../img/human.svg';
import { ReactComponent as Wheelchair } from '../img/wheelchair.svg';
import { ReactComponent as WheelchairHeavy } from '../img/wheelchair_heavy.svg';
import { ReactComponent as Stretcher } from '../img/stretcher.svg';
import { ReactComponent as Freight } from '../img/freight.svg';
import { ReactComponent as Question } from '../img/question_mark.svg';

interface VehicleDetailsBubbleProps {
  /** When vehicle is undefined it means selection is currently changing and nothing should be displayed at all. */
  vehicle?: UnitInfo;
  /** The current bookings for the selected vehicle. When null displayed as "loading".
   * Empty array represents successful fetch but no bookings are available for vehicle. */
  bookings: CurrentEvent[] | null;
}

const CapacityIconComponents: {
  [type: number]: React.FC<SVGProps<SVGSVGElement>>;
} = {
  1: Human,
  2: Wheelchair,
  3: Stretcher,
  4: WheelchairHeavy,
  5: Freight,
  6: Question,
};

const VehicleDetailsBubble: FC<VehicleDetailsBubbleProps> = ({
  vehicle,
  bookings,
}) => {
  if (!vehicle) {
    console.log('No vehicle');
    return null;
  }

  return (
    <div className="Bubble VehicleDetailsBubble">
      <div className="VehicleInformation">
        <div className="VehicleIdText">
          <div className="VehicleIdTextRight">{vehicle.para_run_name}</div>
          <div className="VehicleIdTextLeft">{vehicle.unit_id}</div>
        </div>
        <p className="CapacityText">{text('capacity')}</p>
        {vehicle.capacities.map((cap, i) => {
          if (!cap.badge && (cap.type > 5 || cap.type < 1)) {
            return null;
          }

          const IconComponent = CapacityIconComponents[cap.type];
          let hover_help = '';
          if (cap.tooltip) {
            hover_help = cap.tooltip;
          } else {
            switch (cap.type) {
              case 1:
                hover_help = text('human');
                break;
              case 2:
                hover_help = text('wheelchair');
                break;
              case 3:
                hover_help = text('stretcher');
                break;
              case 4:
                hover_help = text('wheelchair_heavy');
                break;
              case 5:
                hover_help = text('freight');
                break;
              default:
                hover_help = text('human');
                break;
            }
          }

          return (
            <div className="CapacityCell" key={cap.type + '-' + i}>
              {cap.badge && <div className="CapacityBadge">{cap.name}</div>}
              <Tooltip content={hover_help} position={Position.BOTTOM}>
                <IconComponent className="CapacityIcon" />
              </Tooltip>
              <div className="CapacityCellCount">
                <span className="bp3-text-small Bold">x{cap.max}</span>
              </div>
            </div>
          );
        })}
      </div>

      <Booking bookings={bookings} />
    </div>
  );
};

export default VehicleDetailsBubble;
