let st_groups_mnemonic: [number[], number[]] | null | undefined = undefined;
export const stGroups = (
  vehicles: UnitInfo[],
  recalc: boolean,
): [number[], number[]] | null => {
  if (st_groups_mnemonic !== undefined && !recalc) {
    return st_groups_mnemonic;
  }
  st_groups_mnemonic = [
    [1, 2, 3, 4],
    [5, 6, 7, 8],
  ];
  return st_groups_mnemonic || null;
};

export const stLegendTypes = (): UnitType[] => {
  return ['car', 'minibus', 'minibus_w_bed', 'stairclimber'];
};
