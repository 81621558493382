type Action = { type: 'set'; bus_updates: VehicleInfo[] };

const reducer = (state: BusState, action: Action): BusState => {
  switch (action.type) {
    case 'set': {
      return {
        all: action.bus_updates.sort((a, b) =>
          a.unit_id > b.unit_id ? 1 : -1,
        ),
        by_id: action.bus_updates.reduce((acc, curr) => {
          acc[curr.unit_id] = curr;
          return acc;
        }, {} as { [vehicle_id: string]: VehicleInfo }),
      };
    }
    default:
      return state;
  }
};

export default reducer;
