import React, { FC } from 'react';
import oh from 'output-helpers';

import text from '../text';
import './BusDetailsStyle.css';

interface Props {
  /** The currently selected bus details. */
  details?: FetchedBusDetails;
}

const BusDetails: FC<Props> = ({ details }) => {
  if (!details) {
    return null;
  }
  return (
    <div className="Bubble BusDetailsBubble">
      <div className="BubbleHeader">
        <p className="BubbleTitle">
          {text('bus_information')} {details.rt.LineNr}
        </p>
      </div>
      <div className="Body">
        <div className="HeadingText">{text('next_bus_stop')}</div>
        <div className="ValueText">
          <strong>{details.rt.NextHplArrival}</strong>&nbsp;&nbsp;&nbsp;
          {details.rt.NextHplName}
        </div>
        <div className="HeadingText">{text('punctuality')}</div>
        <div className="ValueText">
          {oh.formatSecondsToMS(
            Math.round(details.rt.CurrentDelay * 60),
            'both',
          )}
        </div>
        <div className="HeadingText">{text('last_report')}</div>
        <div className="ValueText">{details.rt.LastReport}</div>
      </div>
    </div>
  );
};

export default BusDetails;
