/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
//-- THESE DISABLES ARE BECAUSE OF GOOGLE API MISSING SOME TYPE DEFINITIONS --

import { icon_by_type_and_color as st_icon_by_type_and_color } from './st_styles';
import { icon_by_type_and_color as bt_icon_by_type_and_color } from './bt_styles';

import IconEmpty from '../img/empty.svg';
import IconRoadWorks from '../img/disruptions/road_work.svg';
import IconLimited from '../img/disruptions/limited.svg';
import IconBlocked from '../img/disruptions/blocked.svg';

import IconUnscheduled from '../img/unscheduled.svg';
import IconGarage from '../img/garage.svg';
import IconGarageSelected from '../img/garage_selected.svg';
import BusIcon from '../img/bus.svg';
import BusIconSelected from '../img/bus_selected.svg';
import BusTargetIcon from '../img/bus.svg';

import WaypointMarkerR from '../img/waypoints/waypoint_r.svg';
import WaypointMarkerO from '../img/waypoints/waypoint_o.svg';
import WaypointMarkerY from '../img/waypoints/waypoint_y.svg';
import WaypointMarkerG from '../img/waypoints/waypoint_g.svg';
import WaypointMarkerC from '../img/waypoints/waypoint_c.svg';
import WaypointMarkerB from '../img/waypoints/waypoint_b.svg';
import WaypointMarkerP from '../img/waypoints/waypoint_p.svg';
import WaypointMarkerF from '../img/waypoints/waypoint_f.svg';
import WaypointMarkerM from '../img/waypoints/waypoint_m.svg';

import SearchResultPOIMarker from '../img/marker.svg';

import RedClusterIcon from '../img/vehicle_marker/red-cluster.svg';
import OrangeClusterIcon from '../img/vehicle_marker/orange-cluster.svg';
import YellowClusterIcon from '../img/vehicle_marker/yellow-cluster.svg';
import GreenClusterIcon from '../img/vehicle_marker/green-cluster.svg';
import CyanClusterIcon from '../img/vehicle_marker/cyan-cluster.svg';
import BlueClusterIcon from '../img/vehicle_marker/blue-cluster.svg';
import PurpleClusterIcon from '../img/vehicle_marker/purple-cluster.svg';
import RoseClusterIcon from '../img/vehicle_marker/rose-cluster.svg';
import PinkClusterIcon from '../img/vehicle_marker/pink-cluster.svg';
import DarkBlueClusterIcon from '../img/vehicle_marker/dark_blue-cluster.svg';

import { Config } from '../config';

declare const google: any;

type Icon = {
  url: string;
  labelOrigin?: any;
  anchor?: any;
  scaledSize?: any;
};

type Label = {
  color?: string;
  fontFamily?: string;
  fontSize?: string;
  fontWeight?: string;
  text: string;
};

const cluster_icon_by_group: {
  [color in GroupColor]: string;
} = {
  blue: BlueClusterIcon,
  cyan: CyanClusterIcon,
  dark_blue: DarkBlueClusterIcon,
  green: GreenClusterIcon,
  orange: OrangeClusterIcon,
  pink: PinkClusterIcon,
  purple: PurpleClusterIcon,
  red: RedClusterIcon,
  rose: RoseClusterIcon,
  yellow: YellowClusterIcon,
};

const getTypeAndColorIcons = (
  config: Config,
): {
  [color in GroupColor]: {
    [type in UnitType]: string;
  };
} => {
  switch (config.service_name) {
    case 'ntd_ui_skane':
      return st_icon_by_type_and_color;
    case 'ntd_ui_bt':
      return bt_icon_by_type_and_color;
    default:
      throw new Error('Invalid service when resolving vehicle type icons.');
  }
};

const waypoint_icon_by_index: {
  [key: number]: string;
} = {
  0: WaypointMarkerB,
  1: WaypointMarkerR,
  2: WaypointMarkerC,
  3: WaypointMarkerM,
  4: WaypointMarkerY,
  5: WaypointMarkerG,
  6: WaypointMarkerO,
  7: WaypointMarkerP,
  8: WaypointMarkerF,
};

export const getClusterIcon = (group: number, config: Config): string => {
  return cluster_icon_by_group[config.color_by_group[group]];
};

export const getEmptyIcon = (): Icon => {
  return {
    url: IconEmpty,
    labelOrigin: new google.maps.Point(6, 6),
    anchor: new google.maps.Point(3, 3),
  };
};

export const getGarageIcon = (selected?: true): Icon => {
  if (selected) {
    return {
      url: IconGarageSelected,
      anchor: new google.maps.Point(25, 25),
      labelOrigin: new google.maps.Point(25, 55),
    };
  }
  return {
    url: IconGarage,
    anchor: new google.maps.Point(25, 25),
    labelOrigin: new google.maps.Point(25, 55),
  };
};

export const getUnscheduledIcon = (): Icon => {
  return {
    url: IconUnscheduled,
    anchor: new google.maps.Point(25, 25),
  };
};

export const getDisruptionIcon = (disruption_type: DisruptionType): Icon => {
  switch (disruption_type) {
    case 'road_works':
      return {
        url: IconRoadWorks,
        anchor: new google.maps.Point(25, 25),
      };
    case 'limited':
      return {
        url: IconLimited,
        anchor: new google.maps.Point(25, 25),
      };
    case 'blocked':
      return {
        url: IconBlocked,
        anchor: new google.maps.Point(25, 25),
      };
    default: {
      throw new Error('Invalid input to getDisruptionIcon: ' + disruption_type);
    }
  }
};

export const getVehicleIcon = (
  vehicle_type: UnitType,
  group: number,
  config: Config,
): Icon => {
  let color = config.color_by_group[group];
  if (!color) {
    console.warn('Missing color for group:', group);
    color = 'red';
  }
  return {
    url: getTypeAndColorIcons(config)[color][vehicle_type],
    labelOrigin: new google.maps.Point(49, 12),
    anchor: new google.maps.Point(40, 30),
  };
};
export const getBusIcon = (selected?: true): Icon => ({
  url: selected ? BusIconSelected : BusIcon,
  labelOrigin: new google.maps.Point(15, 15),
  anchor: new google.maps.Point(15, 15),
  scaledSize: new google.maps.Size(30, 30),
});
export const getBusTargetIcon = (): Icon => ({
  url: BusTargetIcon,
  labelOrigin: new google.maps.Point(35, 15),
  anchor: new google.maps.Point(15, 15),
  scaledSize: new google.maps.Size(30, 30),
});

type Style = Pick<
  import('google-maps-wrapper').GMW_PolylineOptions,
  | 'clickable'
  | 'draggable'
  | 'strokeColor'
  | 'strokeOpacity'
  | 'strokeWeight'
  | 'editable'
  | 'visible'
>;

/** Style for the path drawn between previous and next booking leg when a vehicle is selected. */
export const getPathStyle = (vehicle_type?: UnitType): Style => {
  switch (vehicle_type) {
    default: {
      return {
        strokeColor: '#000000',
        strokeOpacity: 0.75,
        strokeWeight: 5,
        visible: true,
        editable: false,
        clickable: false,
      };
    }
  }
};
/** Style for the path drawn behind a selected vehicle. */
export const getTraceStyle = (group: number, config: Config): Style => {
  const color = config.color_by_group[group];
  if (!color) {
    throw new Error(
      'Color not specified for group in getTraceStyle for group: ' +
        group +
        '.',
    );
  }
  return {
    strokeColor: config.hex_by_color[color],
    strokeWeight: 7,
    visible: true,
    editable: false,
    clickable: false,
  };
};

export const getSearchResultMarkerIcon = (): Icon => {
  return {
    url: SearchResultPOIMarker,
    labelOrigin: new google.maps.Point(18, 60),
    anchor: new google.maps.Point(18, 50),
  };
};
/** Style for the path drawn between previous and next booking leg when a vehicle is selected. */
export const getSearchResultPathStyle = (): Style => {
  return {
    strokeColor: '#4355A7',
    strokeOpacity: 0.75,
    strokeWeight: 5,
    editable: false,
    clickable: false,
  };
};

/** Icon and label styles for the waypoints of selected vehicle. */
export const getWaypointIconAndLabel = (
  vehicle: UnitInfo,
  icon_index: number,
  index: number,
): {
  icon: Icon;
  label: Label;
} => {
  if (!vehicle) {
    throw new Error('Missing vehicle in getWaypointIconAndLabel.');
  }
  //TODO: STYLE: Updated the icons with colors to match the colors used in 'src/Booking/Booking.tsx' list.
  //NOTE: The index starts at 0!
  return {
    icon: {
      url: waypoint_icon_by_index[icon_index],
      labelOrigin: new google.maps.Point(16, 16),
      anchor: new google.maps.Point(16, 16),
    },
    label: {
      text: (index + 1).toString(),
      color: '#fff',
      fontSize: '16px',
      fontWeight: '600',
    },
  };
};
