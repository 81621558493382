type Action = { type: 'set'; unit_updates: UnitInfo[] };

const reducer = (state: VehicleState, action: Action): VehicleState => {
  switch (action.type) {
    case 'set': {
      return {
        all: action.unit_updates.sort((a, b) =>
          a.vehicle_id > b.vehicle_id ? 1 : -1,
        ),
        by_id: action.unit_updates.reduce((acc, curr) => {
          acc[curr.vehicle_id] = curr;
          return acc;
        }, {} as { [vehicle_id: string]: UnitInfo }),
      };
    }
    default:
      return state;
  }
};

export default reducer;
