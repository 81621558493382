export interface Config {
  api: {
    pass: Record<'uri', string>;
    core?: Record<'uri', string>;
    avl: Record<'uri', string>;
    google: Record<'maps_uri', string>;
  };
  /** URIs to notification servers (websocket targets). */
  notifications: {
    disruptions: Record<'wss', string>;
  };
  map: {
    center: import('google-maps-wrapper').GMW_LatLngLiteral;
    zoom: number;
    style?: {
      [key: string]: any;
    }[];
  };
  default_language: 'sv-SE' | 'en-US';
  service_name: ServiceName;
  /** HTML document title. */
  document_title: string;
  mock_api?: boolean;
  /** The number of steps to display in the trace of a selected vehicle. */
  selected_vehicle_trace_steps: number;
  /** How often to fetch unscheduled bookings. Seconds. */
  unscheduled_update_frequency: number;
  /** How often to fetch unit updates. Seconds. */
  unit_update_frequency: number;
  /** How often to fetch unit updates. Seconds. */
  bus_update_frequency: number;
  /** How often to fetch bookings when a unit is selected. Seconds. */
  bookings_update_frequency: number;
  /** How often to update the details and trace for a selected bus. Seconds. */
  selected_bus_update_frequency: number;
  /** Size of a cluster in pixels on the screen. */
  cluster_size: number;
  /** Map zoom level at which clusters are disabled. */
  cluster_max_zoom: number;
  /** Minimum number of vehicles required to create a cluster. */
  cluster_min_size: number;
  /** Hex values for the 8 group colors. Used on map and in legend. */
  hex_by_color: {
    [color in GroupColor]: string;
  };
  /** Object to match a group number to its assigned color. */
  color_by_group: {
    [group: number]: GroupColor;
  };
  /** Color available for booking pairs */
  booking_colors: string[];
  /** The zoom level to use when panning to a vehicle after searching for it. */
  vehicle_search_result_zoom: number;
  default_visiblity: ShowState;
  available_tabs: AvailableTabs;
  garages?: Omit<Garage, 'id'>[];
}

const config: Config = {
  //TODO: Mock api false before release
  mock_api: true,
  api: {
    pass: {
      uri: 'http://localhost:810/PASSVC.svc/json',
    },
    avl: {
      uri: 'http://localhost:811/AVL.svc/json',
    },
    core: {
      uri: 'http://localhost:1199/CoreAPI.svc/json',
    },
    google: {
      maps_uri:
        'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,places,drawing&key=AIzaSyA0tp0r6ImLSnn9vy4zXjZWar1F3U5eOaY',
    },
  },
  service_name: 'ntd_ui_skane',
  document_title: 'NTD 1.5',
  selected_vehicle_trace_steps: 10,
  default_language: 'sv-SE',
  notifications: {
    disruptions: {
      wss: 'wss://ntd-disruption-api.test.allbin.se',
    },
  },
  unscheduled_update_frequency: 30,
  unit_update_frequency: 5,
  bus_update_frequency: 5,
  selected_bus_update_frequency: 10,
  bookings_update_frequency: 30,
  cluster_size: 128,
  cluster_max_zoom: 15,
  cluster_min_size: 3,
  vehicle_search_result_zoom: 13,
  hex_by_color: {
    blue: '#1F93FF',
    cyan: '#00D292',
    green: '#1CBC16',
    orange: '#ff8c00',
    pink: '#ff00d4',
    purple: '#594EFF',
    red: '#d90e00',
    rose: '#7d7d7d',
    yellow: '#e0d100',
    dark_blue: '#0004e8',
  },
  default_visiblity: {
    vehicles: true,
    disruptions: false,
    busses: false,
    unscheduled: false,
    garage: false,
  },
  available_tabs: {
    vehicles: true,
    disruptions: true,
    busses: true,
    unscheduled: true,
    garage: true,
  },
  color_by_group: {
    0: 'red', //Used by default if client has not specified vehicle grouping system.
    1: 'orange',
    2: 'yellow',
    3: 'red',
    4: 'green',
    5: 'pink',
    6: 'blue',
    7: 'dark_blue',
    8: 'rose',
    9: 'cyan',
    // 1: 'red',
    // 2: 'orange',
    // 3: 'yellow',
    // 4: 'green',
    // 5: 'cyan',
    // 6: 'blue',
    // 7: 'purple',
    // 8: 'rose',
    // 9: 'pink',
  },
  booking_colors: [
    '#466CCB',
    '#E95842',
    '#17B9AA',
    '#8E7AC1',
    '#FFB632',
    '#00695F',
    '#FD7F1F',
    '#584197',
    '#FF7BB8',
  ],
  map: {
    center: { lat: 55.7, lng: 13.6 },
    zoom: 10,
    style: [
      {
        elementType: 'geometry',
        stylers: [
          {
            color: '#ffffff',
          },
        ],
      },
      {
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161',
          },
        ],
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#F5F5F5',
          },
        ],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#BDBDBD',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: '#EEEEEE',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575',
          },
        ],
      },
      {
        featureType: 'poi.business',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            color: '#E5E5E5',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9E9E9E',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            color: '#D3D9E9',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#D3D9E9',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
          {
            color: '#DADADA',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161',
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9E9E9E',
          },
        ],
      },
      {
        featureType: 'transit',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
          {
            color: '#E5E5E5',
          },
        ],
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
          {
            color: '#EEEEEE',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: '#AADDFF',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9E9E9E',
          },
        ],
      },
    ],
  },
};

export default config;
