import { Config } from '../config';
import { btGroups, btLegendTypes } from './legend_bt';
import { stGroups, stLegendTypes } from './legend_st';

/** 
 Returns null to indicate no groups should show at all.
 Otherwise two columns of group numbers are returned.
*/
export const generateGroups = (
  config: Config,
  vehicles: UnitInfo[],
  force_recalc?: true,
): [number[], number[]] | null => {
  switch (config.service_name) {
    case 'ntd_ui_skane':
      return stGroups(vehicles, force_recalc || false);
    case 'ntd_ui_bt':
      return btGroups(vehicles, force_recalc || false);
    default:
      throw new Error('Unknown service_name for legend group creation.');
  }
};

export const legendTypes = (config: Config): UnitType[] => {
  switch (config.service_name) {
    case 'ntd_ui_skane':
      return stLegendTypes();
    case 'ntd_ui_bt':
      return btLegendTypes();
    default:
      throw new Error('Unknown service_name for legend type creation.');
  }
};
