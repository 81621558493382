import { GMW_LatLngLiteral } from 'google-maps-wrapper';

import { paraRunNameToUnitTypeNumber } from './converters';
import { Config } from '../config';

interface VehicleUniques {
  UnitId: string;
  VehicleId: string;
  VehicleNumber: string;
  ParaRunName: string;
}

const all_units: UnitInfoRes[] = [];
const booking_legs: {
  [unit_id: string]: GetBookingLegsResponse;
} = {};
const vehicle_state: {
  [unit_id: string]: VehicleStateRes[];
} = {};
let unscheduled: CurrentEventRes[] = [];
let bus_avl: VehicleStateRes[] = [];

/////////////////////

const randFloat = (min = 0, max = 1): number =>
  Math.random() * (max - min) + min;

const rand = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1) + min);

/** /Date(1593422154000+0200)/ */
const generateReportTime = (second_offset = 0): string =>
  '/Date(' + (new Date().valueOf() + second_offset) + '+0200)/';

const mock_bounds = {
  lat_max: 56.2,
  lat_min: 55.4,
  lon_max: 14.4,
  lon_min: 13,
};

const movement_bias: {
  [unit_id: string]: {
    x: 1 | -1;
    y: 1 | -1;
  };
} = {};

let update_timer: any = null;

export const isMocksInitialized = (): boolean => all_units.length > 0;

export const initMocks = (config: Config, amount = 200): void => {
  let uniques: VehicleUniques[];
  switch (config.service_name) {
    case 'ntd_ui_skane':
      uniques = stGenerateVehicleUniques(amount);
      break;
    case 'ntd_ui_bt':
      uniques = btGenerateVehicleUniques(amount);
      break;
    default:
      throw new Error('Invalid service_name when generating mocks.');
  }
  // console.log('uniques:', uniques);
  uniques.forEach((unique) => {
    movement_bias[unique.UnitId] = {
      x: Math.random() > 0.5 ? 1 : -1,
      y: Math.random() > 0.5 ? 1 : -1,
    };
    const unit: UnitInfoRes = {
      ...unique,
      Heading: 0,
      Capacities: generateUnitCapacities(),
      ParaRunName: unique.ParaRunName,
      Type: paraRunNameToUnitTypeNumber(config, unique.ParaRunName),
      ReportTime: generateReportTime(),
      Lat: 0,
      Lon: 0,
    };
    all_units.push(unit);
    updateUnits(); //Will create the first VehicleState and update the units' data accordingly.
  });

  unscheduled = generateCurrentEvents(10, false);

  if (update_timer) {
    clearInterval(update_timer);
  }
  update_timer = setInterval(() => {
    //Update vehicle states every 5 seconds.
    updateUnits();
  }, 5000);
};

/** Generate new position based on input with a bias towards a
 * certain direction. Reverse the direction if too close to bounds. */
const generateLatLng = (
  id: string,
  lat: number,
  lon: number,
): GMW_LatLngLiteral => {
  const bias = movement_bias[id];

  const new_lat_min = Math.max(
    mock_bounds.lat_min,
    lat - 0.005 * (bias.x < 0 ? 2 : 1),
  );
  const new_lat_max = Math.min(
    mock_bounds.lat_max,
    lat + 0.005 * (bias.x < 0 ? 1 : 2),
  );
  const new_lat = randFloat(new_lat_min, new_lat_max);

  const new_lon_min = Math.max(
    mock_bounds.lon_min,
    lon - 0.005 * (bias.y < 0 ? 2 : 1),
  );
  const new_lon_max = Math.min(
    mock_bounds.lon_max,
    lon + 0.005 * (bias.y < 0 ? 1 : 2),
  );
  const new_lon = randFloat(new_lon_min, new_lon_max);

  // console.log("lat", lat);
  // console.log("bias:", bias);
  // console.log("min, max", new_lat_min, new_lat_max);
  // console.log("new_lat:", new_lat);
  // console.log("-----");

  if (new_lat - mock_bounds.lat_min < 0.02 && bias.x < 0) {
    movement_bias[id].x = 1;
  }
  if (mock_bounds.lat_max - new_lat < 0.01 && bias.x > 0) {
    movement_bias[id].x = -1;
  }
  if (new_lon - mock_bounds.lon_min < 0.02 && bias.y < 0) {
    movement_bias[id].y = 1;
  }
  if (mock_bounds.lon_max - new_lon < 0.01 && bias.y > 0) {
    movement_bias[id].y = -1;
  }

  return {
    lat: new_lat,
    lng: new_lon,
  };
};

const updateUnits = (): void => {
  all_units.map((unit) => {
    const unit_id = unit.UnitId;
    const lat =
      vehicle_state[unit_id] && vehicle_state[unit_id].length > 0
        ? vehicle_state[unit_id][0].Location.Latitude
        : randFloat(mock_bounds.lat_min, mock_bounds.lat_max);
    const lon =
      vehicle_state[unit_id] && vehicle_state[unit_id].length > 0
        ? vehicle_state[unit_id][0].Location.Longitude
        : randFloat(mock_bounds.lon_min, mock_bounds.lon_max);
    const new_vehicle_state = generateVehicleState(
      unit_id,
      unit.Type,
      lat,
      lon,
      'PASS1',
      -1,
    );
    const cont = vehicle_state[unit_id] || [];
    cont.unshift(new_vehicle_state);
    if (cont.length > 150) {
      cont.pop();
    }

    unit.ReportTime = new_vehicle_state.ReportTime;
    unit.Lat = new_vehicle_state.Location.Latitude;
    unit.Lon = new_vehicle_state.Location.Longitude;

    vehicle_state[unit.UnitId] = cont;
    return unit;
  });
};

const generateVehicleState = (
  unit_id: string,
  type: number,
  lat: number,
  lon: number,
  report_type: 'PASS1' | 'ConsatBT',
  line_nr: number,
): VehicleStateRes => {
  const new_pos = generateLatLng(unit_id, lat, lon);

  return {
    UnitId: report_type === 'PASS1' ? 'PASS' + unit_id : unit_id,
    Datasource: report_type,
    Delay: 0,
    Direction: -1,
    DoorOpen: null,
    Heading: 0,
    LineNr: line_nr,
    Location: {
      Latitude: new_pos.lat,
      Longitude: new_pos.lng,
    },
    ReportTime: generateReportTime(rand(1, 3) * 1000),
    Speed: -1,
    TripNr: -1,
    TripStatus: null,
    UnitType: type,
    Variant: 0,
  };
};

/** Use booked:true to have ETA, ETD, DwellTime and EstTime be non-negative. */
const generateCurrentEvents = (
  amount: number,
  booked: boolean,
): CurrentEventRes[] => {
  const streets = [
    'Rönnowsgatan',
    'Spannmålsgatan',
    'Rektorsgatan',
    'Järnvägsg',
  ];
  const addrname = [
    'Läkargruppen',
    'Johnnys pizza',
    ' ',
    ' ',
    ' ',
    ' ',
    ' ',
    ' ',
    "Anderson's",
    'Stånga vårdcentral',
  ];
  const reqs = [-1, -1, -1, -1, 43200, 45000, 46800, 45000];
  let tt = rand(100, 300);
  let eta = rand(30000, 40000);
  let etd = Math.round(eta + rand(100, 1000));
  const generatePickupEvent = (): CurrentEventRes => {
    const ev: CurrentEventRes = {
      City: 'Some city name Some city name Some city name',
      OnStreet: streets[rand(0, streets.length - 1)],
      AddrName: booked ? addrname[rand(0, addrname.length - 1)] : ' ',
      TravelTime: tt,
      ReqEarly: reqs[rand(0, reqs.length - 1)],
      ReqLate: booked ? reqs[rand(0, reqs.length - 1)] : -1,
      ReqTime: reqs[rand(0, reqs.length - 1)],
      DwellTime: booked ? rand(100, 400) : 0,
      ETA: booked ? eta : 0,
      ETD: booked ? etd : 0,
      EstTime: booked ? Math.round(eta + (etd - eta) / 2) : 0,
      BookingId: rand(1000000, 2000000),
      EvId: booked ? rand(1000000, 2000000) : 0,
      Lat: randFloat(mock_bounds.lat_min, mock_bounds.lat_max),
      Lon: randFloat(mock_bounds.lon_min, mock_bounds.lon_max),
      SpaceOff: [],
      SpaceOn: [generateSpace()],
      SpaceOnBoard: randFloat() > 0.5 ? [generateSpace()] : [],
    };

    tt += Math.round(rand(100, 400));
    eta += Math.round(rand(100, 1000));
    etd = eta + Math.round(rand(100, 1000));
    return ev;
  };

  const generateDropoffEventFromPickup = (
    pickup: CurrentEventRes,
  ): CurrentEventRes => {
    const ev: CurrentEventRes = {
      City: 'Some city name',
      OnStreet: streets[rand(0, streets.length - 1)],
      AddrName: booked ? addrname[rand(0, addrname.length - 1)] : ' ',
      TravelTime: tt,
      ReqEarly: reqs[rand(0, reqs.length - 1)],
      ReqLate: booked ? reqs[rand(0, reqs.length - 1)] : -1,
      ReqTime: reqs[rand(0, reqs.length - 1)],
      DwellTime: booked ? rand(100, 400) : 0,
      ETA: booked ? pickup.ETA + 1000 + Math.round(rand(100, 1000)) : 0,
      ETD: booked ? pickup.ETD + 3000 + Math.round(rand(100, 1000)) : 0,
      EstTime: booked ? pickup.EstTime + 2000 + Math.round(rand(100, 1000)) : 0,
      BookingId: pickup.BookingId,
      EvId: booked ? rand(1000000, 2000000) : 0,
      Lat: randFloat(mock_bounds.lat_min, mock_bounds.lat_max),
      Lon: randFloat(mock_bounds.lon_min, mock_bounds.lon_max),
      SpaceOff: [generateSpace()],
      SpaceOn: [],
      SpaceOnBoard: randFloat() > 0.5 ? [generateSpace()] : [],
    };

    return ev;
  };

  const arr: CurrentEventRes[] = [];
  Array(amount)
    .fill(null)
    .forEach(() => {
      const pickup = generatePickupEvent();
      const pos = rand(0, arr.length);
      arr.splice(pos, 0, pickup);

      const dropoff = generateDropoffEventFromPickup(pickup);
      arr.splice(rand(pos + 1, arr.length), 0, dropoff);
    });

  return arr;
};

////////////////////////////////////////////////////7
////////////////////////////////////////////////////7
////////////////////////////////////////////////////7
////////////////////////////////////////////////////7
////////////////////////////////////////////////////7
////////////////////////////////////////////////////7
////////////////////////////////////////////////////7

const stGenerateVehicleUniques = (amount: number): VehicleUniques[] => {
  const letters = [
    'Z',
    'R',
    'P',
    'LBT',
    'LT',
    'LB',
    'L',
    'PA',
    'C',
    'SA',
    'SBA',
    'PA',
  ];

  return Array(amount)
    .fill(undefined)
    .map(() => {
      const unit_id =
        rand(1, 8).toString() +
        rand(1, 7).toString() +
        rand(100, 999).toString();
      const v_number = unit_id + letters[rand(0, letters.length - 1)];
      return {
        VehicleNumber: v_number,
        UnitId: unit_id,
        VehicleId: rand(1000, 89000).toString(),
        ParaRunName: v_number,
      };
    });
};
const btGenerateVehicleUniques = (amount: number): VehicleUniques[] => {
  const days = ['M', 'T', 'O', 'T', 'F', 'L', 'S'];
  const types = ['B', 'BB', 'P'];

  const getDays = (n: number): string[] => {
    const chosen: string[] = [];
    while (chosen.length < n) {
      const d = days[rand(0, days.length - 1)];
      if (!chosen.includes(d)) {
        chosen.push(d);
      }
    }
    return chosen;
  };

  return Array(amount)
    .fill(undefined)
    .map(() => {
      let unit_number = rand(1, 200).toString();
      if (unit_number.length === 1) {
        unit_number = '0' + unit_number;
      }
      let para =
        getDays(rand(1, 4)).join('') +
        unit_number +
        types[rand(0, types.length - 1)];

      //Some vehicles have added characters to the PRN.
      if (rand(0, 10) > 8) {
        para += '1fm';
      }
      return {
        ParaRunName: para,
        VehicleNumber: unit_number,
        UnitId: unit_number,
        VehicleId: '6' + unit_number,
      };
    });
};
const generateUnitCapacities = (): UnitCapacityRes[] => {
  const names = ['', 'EL', 'GT', 'GÅ', 'LI', 'RS'];
  const arr = [1, 2, 3, 4, 5];
  arr.sort(() => rand(-1, 1));

  const getCapacity = (): UnitCapacityRes => {
    const max = rand(1, 10);
    return {
      Max: max,
      Min: rand(1, max),
      Name: names[rand(0, names.length - 1)],
      Type: arr.pop() || 1,
    };
  };
  return Array(rand(1, 3)).fill(undefined).map(getCapacity);
};

export const mockGetAllUnits = (): GetAllUnitsResponse => {
  if (all_units.length === 0) {
    throw new Error('Mocks not initialized.');
  }
  return all_units;
};

////////////////////////////////////////////////////////7
////////////////////////////////////////////////////////7
////////////////////////////////////////////////////////7
////////////////////////////////////////////////////////7
////////////////////////////////////////////////////////7
////////////////////////////////////////////////////////7

const generateSpace = (): BookingSpaceRes => {
  return {
    Type: rand(1, 5),
    Count: rand(1, 2),
  };
};

/** Should generate CurrentEvents where ETA, ETD, DwellTime and EstTime are non-negative.
 * NOTE: Unlike real data the same booking-id for a pickup and dropoff are not available!!
 */
export const mockGetBookingLegs = (unit_id: string): GetBookingLegsResponse => {
  if (!vehicle_state[unit_id]) {
    //Check that this unit actually exists in the mock api.
    // throw new Error("MOCK API ERROR: vehicle_id not found.");
    console.error('MOCK API ERROR: unit_id not found. Returning empty events.');
    return {
      CurrentEvents: [],
      MtdId: 50000,
    };
  }
  booking_legs[unit_id] = booking_legs[unit_id] || {
    CurrentEvents: generateCurrentEvents(rand(0, 5), true),
    MtdId: 50000,
  };
  return booking_legs[unit_id];
};

////////////////////////////////////////////////////////7
////////////////////////////////////////////////////////7
////////////////////////////////////////////////////////7
////////////////////////////////////////////////////////7
////////////////////////////////////////////////////////7
////////////////////////////////////////////////////////7

export const mockGetVehicleState = (
  unit_id: string,
): GetVehicleStateResponse => {
  if (!vehicle_state[unit_id]) {
    // throw new Error("MOCK API ERROR: vehicle_id not found.");
    console.error(
      'MOCK API ERROR: unit_id not found. Returning empty AVLReports.',
    );
    return {
      AVLReports: [],
    };
  }
  return {
    AVLReports: vehicle_state[unit_id],
  };
};

////////////////////////////////////////////////////////7
////////////////////////////////////////////////////////7
////////////////////////////////////////////////////////7
////////////////////////////////////////////////////////7
////////////////////////////////////////////////////////7
////////////////////////////////////////////////////////7

export const mockGetUnbooked = (): GetUnplannedBookingResponse => {
  return {
    CurrentEvents: unscheduled,
    MtdId: -1,
  };
};

////////////////////////////////////////////////////////7
////////////////////////////////////////////////////////7
////////////////////////////////////////////////////////7
////////////////////////////////////////////////////////7
////////////////////////////////////////////////////////7
////////////////////////////////////////////////////////7

const generateBusAVLReport = (
  old_report: Partial<VehicleStateRes>,
): VehicleStateRes => {
  const unit_id = old_report.UnitId || 'CNT' + rand(100, 199) + rand(100, 999);
  const line_nr =
    old_report && old_report.LineNr ? old_report.LineNr : rand(0, 950);
  if (!movement_bias[unit_id]) {
    movement_bias[unit_id] = {
      x: Math.random() > 0.5 ? 1 : -1,
      y: Math.random() > 0.5 ? 1 : -1,
    };
  }

  const lat = old_report.Location
    ? old_report.Location.Latitude
    : randFloat(mock_bounds.lat_min, mock_bounds.lat_max);
  const lon = old_report.Location
    ? old_report.Location.Longitude
    : randFloat(mock_bounds.lon_min, mock_bounds.lon_max);
  const report = generateVehicleState(
    unit_id,
    0,
    lat,
    lon,
    'ConsatBT',
    line_nr,
  );
  return report;
};

const generateBusAVLReports = (): VehicleStateRes[] => {
  const new_reports: VehicleStateRes[] = [];
  const arr: VehicleStateRes[] | null[] =
    bus_avl.length > 0 ? bus_avl : new Array(40).fill(null);
  arr.forEach((report: VehicleStateRes | null) => {
    new_reports.push(generateBusAVLReport(report || {}));
  });
  return new_reports;
};

export const mockGetAVLState = (): GetVehicleStateResponse => {
  bus_avl = generateBusAVLReports();
  return {
    AVLReports: [...bus_avl],
  };
};

export const mockGetBusState = (unit_id: string): GetVehicleStateResponse => {
  const bus = bus_avl.find((x) => x.UnitId === unit_id);
  if (!bus) {
    // throw new Error("MOCK API ERROR: vehicle_id not found.");
    console.error(
      'MOCK API ERROR: unit_id for bus not found. Returning empty AVLReports.',
    );
    return {
      AVLReports: [],
    };
  }
  const new_reports: VehicleStateRes[] = [];
  new Array(10).fill(null).forEach(() => {
    const old_report =
      new_reports.length > 0 ? new_reports[new_reports.length - 1] : bus;
    const new_report = generateBusAVLReport(old_report);
    new_reports.push(new_report);
  });
  return {
    AVLReports: new_reports,
  };
};

const base_realtime_unit: GetRealTimeUnitsResponse = {
  Cog: 0,
  Lat: 0,
  Lon: 0,
  UnitId: '',
  CurrentDelay: 100,
  CurrentTrip: 1,
  Destination: '',
  LastReport: '18:45',
  LineNr: 100,
  NextHplArrival: '18:58',
  NextHplName: 'Wubba Lubba Dub Dub!',
  NextHplId: 0,
  Speed: 0,
  TrackerStatus: 'OK',
};
export const mockGetRealTimeUnits = (
  bus_id_number: string,
): GetRealTimeUnitsResponse[] => {
  const units: GetRealTimeUnitsResponse[] = [];
  for (let i = 0; i < 50; i++) {
    const new_unit = { ...base_realtime_unit };
    if (i === 0) {
      new_unit.CurrentDelay = randFloat(0.1, 5);
      new_unit.UnitId = bus_id_number;
      const bus = bus_avl.find((x) => x.UnitId === 'CNT' + bus_id_number);
      if (bus) {
        new_unit.Lon = bus.Location.Longitude;
        new_unit.Lat = bus.Location.Latitude;
        new_unit.LineNr = bus.LineNr;
      } else {
        new_unit.Lat = randFloat(mock_bounds.lat_min, mock_bounds.lat_max);
        new_unit.Lon = randFloat(mock_bounds.lon_min, mock_bounds.lon_max);
      }
    }
    units.push(new_unit);
  }
  return units;
};
