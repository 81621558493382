import React, { FC, useState } from 'react';
import { Button, InputGroup } from '@blueprintjs/core';
import { executeAddressSearch, executeVehicleSearch } from './search_funcs';

import text from '../text';
import VehicleSelect from './VehicleSelect';
import { GMW_LatLngLiteral } from 'google-maps-wrapper';

const doSingleSearch = (
  map_funcs: ExportedFuncs,
  setValue: React.Dispatch<React.SetStateAction<string>>,
  vehicles: VehicleState,
  type: 'vehicle' | 'address',
  input: string,
  onSearched: (value: string, pos?: GMW_LatLngLiteral) => void,
): void => {
  if (type === 'vehicle') {
    executeVehicleSearch(map_funcs, vehicles, input)
      .then((res) => {
        onSearched(res.vehicle_id + '|' + res.unit_id);
      })
      .catch(() => {
        console.debug('Vehicle search did not return any result.');
      });
  } else {
    executeAddressSearch(map_funcs, input)
      .then((res) => {
        setValue(res.address);
        onSearched(res.address, res.location);
      })
      .catch((err) => {
        if (err === 'Empty.') {
          console.warn('Address search gave zero results.');
          return;
        }
        throw err;
      });
  }
};

interface SingleInputSearchProps {
  map_funcs: ExportedFuncs;
  field_one: 'vehicle' | 'address';
  vehicles: VehicleState;
  onClearSearch: () => void;
  onSearched: (value: string, pos?: GMW_LatLngLiteral) => void;
}
const SingleInputSearch: FC<SingleInputSearchProps> = ({
  field_one,
  onClearSearch,
  map_funcs,
  vehicles,
  onSearched,
}) => {
  const [value, setValue] = useState<string>('');
  return (
    <div className="AddressSearch">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (value.length > 0) {
            doSingleSearch(
              map_funcs,
              setValue,
              vehicles,
              field_one,
              value,
              onSearched,
            );
          }
        }}
      >
        <div className="FormRow">
          {field_one === 'vehicle' && (
            <VehicleSelect
              onChange={setValue}
              value={value}
              vehicles={vehicles}
              defaultValue={text('choose_vehicle_id')}
            />
          )}
          {field_one === 'address' && (
            <InputGroup
              fill
              large
              placeholder={text('address')}
              leftIcon="globe"
              value={value}
              rightElement={
                <Button
                  icon="delete"
                  className="BtnReset"
                  disabled={value.length < 3}
                  onClick={() => {
                    onClearSearch();
                    setValue('');
                  }}
                />
              }
              //Following disable because onChange typing seems wrong, it claims 'ev' is any.
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              onChange={(ev: any) => setValue(ev.target.value)}
            />
          )}
          <button
            className="Btn SearchPerformSearch"
            type="submit"
            disabled={value.length < 3}
          >
            <p>{text('find')}</p>
          </button>
        </div>
      </form>
    </div>
  );
};

export default SingleInputSearch;
