import React, { FC } from 'react';

import './BookingNumberBoxStyle.css';

interface BookingNumberBoxProps {
  number: number;
  color: string;
}

const BookingNumberBox: FC<BookingNumberBoxProps> = ({ number, color }) => {
  return (
    <div className="BookingNumberBox" style={{ backgroundColor: color }}>
      <p>{number}</p>
    </div>
  );
};

export default BookingNumberBox;
