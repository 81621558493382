/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */

//NOTE: Above disables because weird types for google direction service.

import { GMW_LatLngLiteral } from 'google-maps-wrapper';

export const getDirections = (
  map_funcs: ExportedFuncs,
  points: GMW_LatLngLiteral[],
): Promise<ResolvedDirections> => {
  const dir_svc = map_funcs.getServices().directionsService;
  const origin = points.shift();
  const destination = points.pop();
  const waypoints =
    points.length > 0
      ? points.slice(0, 8).map((p) => ({ location: p, stopover: false }))
      : undefined;

  return new Promise((resolve, reject) => {
    dir_svc.route(
      {
        origin,
        destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
        waypoints,
      },
      (res, status) => {
        if (status !== google.maps.DirectionsStatus.OK) {
          return reject('Routing request failed.');
        }
        console.log('INFO: Directions request to google has finished.');
        if (res && res.routes && res.routes[0] && res.routes[0].overview_path) {
          resolve({
            bounds: res.routes[0].bounds.toJSON(),
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            path: res.routes[0].overview_path.map((latlng) => latlng.toJSON()),
            distance: res.routes[0].legs[0].distance.value / 1000,
            duration: res.routes[0].legs[0].duration.value,
          });
        } else {
          resolve({
            path: [],
          });
        }
      },
    );
  });
};
