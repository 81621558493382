import {
  paraRunNameToUnitTypeNumber as stParaRunNameToUnitTypeNumber,
  numberTypeToUnitType as stNumberTypeToUnitType,
  convertUnitCapacity as stConvertUnitCapacity,
  getGroup as stGetGroup,
} from './st';
import {
  paraRunNameToUnitTypeNumber as btParaRunNameToUnitTypeNumber,
  numberTypeToUnitType as btNumberTypeToUnitType,
  convertUnitCapacity as btConvertUnitCapacity,
  getGroup as btGetGroup,
} from './bt';
import { Config } from '../config';

export const convertRealTimeUnit = (
  res: GetRealTimeUnitsResponse,
): RealTimeUnit => {
  const converted: RealTimeUnit = {
    ...res,
    ...{ location: { lat: res.Lat, lng: res.Lon } },
  };
  return converted;
};

/** Convert '/Date(1593422265000+0200)/' to Date */
const convertWeirdDate = (weird_date: string): Date => {
  const numbers = parseInt(weird_date.slice(6, -7), 10);
  return new Date(numbers);
};

export const convertVehicleState = (res: VehicleStateRes): VehicleInfo => {
  //NOTE: Every converter needs to ensure the data is valid!
  //Throw if anything is not correct, or otherwise handle it, like discarding!
  //No further checks are made in the system for validity, it is assumed to be as typed!
  //TODO: Validity check.
  return {
    datasource: res.Datasource,
    delay: res.Delay,
    direction: res.Direction,
    door_open: res.DoorOpen,
    heading: res.Heading,
    line_nr: res.LineNr,
    location: {
      lat: res.Location.Latitude,
      lng: res.Location.Longitude,
    },
    report_time: convertWeirdDate(res.ReportTime),
    speed: res.Speed,
    trip_nr: res.TripNr,
    trip_status: res.TripStatus,
    unit_id: res.UnitId,
    unit_type: res.UnitType,
    variant: res.Variant,
  };
};

const convertUnitCapacity = (
  config: Config,
  res: UnitCapacityRes,
): UnitCapacity => {
  switch (config.service_name) {
    case 'ntd_ui_skane':
      return stConvertUnitCapacity(res);
    case 'ntd_ui_bt':
      return btConvertUnitCapacity(res);
    default:
      throw new Error('Invalid servicename when converting capacity.');
  }
};

const numberTypeToUnitType = (
  config: Config,
  unit_type_number: number,
): UnitType => {
  switch (config.service_name) {
    case 'ntd_ui_skane':
      return stNumberTypeToUnitType(unit_type_number);
    case 'ntd_ui_bt':
      return btNumberTypeToUnitType(unit_type_number);
    default:
      throw new Error('Invalid servicename when converting unit number type.');
  }
};

export const paraRunNameToUnitTypeNumber = (
  config: Config,
  para_run_name: string,
): number => {
  switch (config.service_name) {
    case 'ntd_ui_skane':
      return stParaRunNameToUnitTypeNumber(para_run_name);
    case 'ntd_ui_bt':
      return btParaRunNameToUnitTypeNumber(para_run_name);
    default:
      throw new Error('Invalid service_name when resolving unit type number.');
  }
};

const getGroupNumber = (config: Config, ParaRunName: string): number => {
  switch (config.service_name) {
    case 'ntd_ui_skane':
      return stGetGroup(ParaRunName);
    case 'ntd_ui_bt':
      return btGetGroup(ParaRunName);
    default:
      throw new Error(
        'Invalid service when extracting group number from vehicle.',
      );
  }
};

export const convertUnitInfo = (config: Config, res: UnitInfoRes): UnitInfo => {
  //NOTE: Every converter needs to ensure the data is valid!
  //Throw if anything is not correct, or otherwise handle it, like discarding!
  //No further checks are made in the system for validity, it is assumed to be as typed!

  return {
    capacities: res.Capacities.map((x) => {
      return convertUnitCapacity(config, x);
    }),
    heading: res.Heading,
    location: {
      lat: res.Lat,
      lng: res.Lon,
    },
    para_run_name: res.ParaRunName,
    reportTime: convertWeirdDate(res.ReportTime),
    type: numberTypeToUnitType(
      config,
      paraRunNameToUnitTypeNumber(config, res.ParaRunName),
    ),
    unit_id: res.UnitId.toString(),
    vehicle_id: res.VehicleId.toString(),
    vehicle_number: res.VehicleNumber.toString(),
    group: getGroupNumber(config, res.ParaRunName),
  };
};

const convertBookingSpace = (res: BookingSpaceRes): BookingSpace => {
  return {
    count: res.Count,
    type: res.Type,
  };
};

const convertCurrentEvent = (res: CurrentEventRes): CurrentEvent => {
  //NOTE: Every converter needs to ensure the data is valid!
  //Throw if anything is not correct, or otherwise handle it, like discarding!
  //No further checks are made in the system for validity, it is assumed to be as typed!
  //TODO: Validity check.
  return {
    addr_name: res.AddrName,
    booking_id: res.BookingId,
    city: res.City,
    dwell_time: res.DwellTime,
    eta: res.ETA,
    etd: res.ETD,
    est_time: res.EstTime,
    ev_id: res.EvId,
    location: {
      lat: res.Lat,
      lng: res.Lon,
    },
    on_street: res.OnStreet,
    req_early: res.ReqEarly,
    req_late: res.ReqLate,
    req_time: res.ReqTime,
    space_off: res.SpaceOff.map(convertBookingSpace),
    space_on: res.SpaceOn.map(convertBookingSpace),
    space_on_board: res.SpaceOnBoard.map(convertBookingSpace),
    travel_time: res.TravelTime,
  };
};

export const convertBookingLegs = (
  res: GetBookingLegsResponse,
): BookingLegs => {
  //NOTE: Every converter needs to ensure the data is valid!
  //Throw if anything is not correct, or otherwise handle it, like discarding!
  //No further checks are made in the system for validity, it is assumed to be as typed!
  return {
    current_events: res.CurrentEvents.map(convertCurrentEvent),
    mtd_id: res.MtdId,
  };
};
export const convertUnplannedBookings = (
  res: GetUnplannedBookingResponse,
): UnscheduledBooking[] => {
  const u_bookings_by_id: {
    [booking_id: number]: Partial<UnscheduledBooking>;
  } = {};

  res.CurrentEvents.forEach((current_event) => {
    if (
      !Object.prototype.hasOwnProperty.call(
        u_bookings_by_id,
        current_event.BookingId,
      )
    ) {
      u_bookings_by_id[current_event.BookingId] = {
        booking_id: current_event.BookingId,
      };
    }
    if (current_event.SpaceOn.length > 0) {
      u_bookings_by_id[current_event.BookingId].pickup = convertCurrentEvent(
        current_event,
      );
    } else {
      u_bookings_by_id[current_event.BookingId].dropoff = convertCurrentEvent(
        current_event,
      );
    }
  });

  const u_bookings = Object.values(u_bookings_by_id).filter((u_booking) => {
    if (
      u_booking.pickup &&
      u_booking.dropoff &&
      typeof u_booking.booking_id === 'number'
    ) {
      return true;
    }
    console.warn('Missing pair in unscheduled booking response.');
    return false;
  });

  return u_bookings as UnscheduledBooking[];
};
