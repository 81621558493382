import React from 'react';

import { H4, H6, Icon } from '@blueprintjs/core';
import { ReactComponent as SearchResultPOIMarker } from '../img/marker.svg';
import { ReactComponent as Car } from '../img/car.svg';

interface Props {
  address_to_vehicle: AddressToVehicleSearchResults;
}

const AddressToVehicleInfo: React.FC<Props> = ({ address_to_vehicle }) => {
  return (
    <div className="AddressVehicleDetailsBubbleInfo">
      <div className="LocationCell">
        <SearchResultPOIMarker />
        <H4 className="NoGutterBottom">{address_to_vehicle.address_label}</H4>
      </div>
      <div className="Info">
        <Icon iconSize={32} icon="arrow-down" className="Icon" />
        <div className="Text">
          <H6 className="Pill">{address_to_vehicle.distance}</H6>
          <H6 className="Pill NoGutterBottom">
            {address_to_vehicle.traveltime}
          </H6>
        </div>
      </div>
      <div className="LocationCell">
        <Car />
        <H4 className="NoGutterBottom">Fordon: {address_to_vehicle.unit_id}</H4>
      </div>
    </div>
  );
};

export default AddressToVehicleInfo;
