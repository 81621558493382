import React, { FC } from 'react';
import text from '../text';

import BookingNumberBox from './BookingNumberBox';
import './BookingStyle.css';
import Human from '../img/human.svg';
import Wheelchair from '../img/wheelchair.svg';
import WheelchairHeavy from '../img/wheelchair_heavy.svg';
import Stretcher from '../img/stretcher.svg';
import Freight from '../img/freight.svg';
import { ReactComponent as Car } from '../img/car.svg';

import moment from 'moment';
import withConfig from 'with-config';
import { Config } from '../config';

interface BookingInfoProps {
  booking: CurrentEvent;
  index: number;
  color: string;
}

const CapacityTypes: { [type: number]: string } = {
  1: Human,
  2: Wheelchair,
  3: Stretcher,
  4: WheelchairHeavy /* heavy / electrical */,
  5: Freight,
};

const convertTime = (seconds: number): string =>
  moment('00:00', 'HH:mm').seconds(seconds).format('HH:mm');

const BookingInfo: FC<BookingInfoProps> = ({ booking, index, color }) => {
  const address =
    booking.addr_name && booking.addr_name.length > 1
      ? booking.addr_name
      : booking.on_street;

  const city =
    booking.city && booking.city.length > 1 ? ', ' + booking.city : '';

  const est_time =
    booking.est_time && booking.est_time > 0
      ? convertTime(booking.est_time)
      : '';
  const alt_time =
    booking.req_time && booking.req_time > 0
      ? 'avt. ' + convertTime(booking.req_time)
      : booking.eta && booking.eta > 0
      ? convertTime(booking.eta)
      : null;

  const full_time = est_time + (alt_time ? ` (${alt_time})` : '');

  return (
    <div className="BookingListItem">
      <div className="BookingNumber">
        <BookingNumberBox number={index} color={color} />
      </div>
      <div className="BookingCapacity">
        {booking.space_on.map((space_def) =>
          Array(space_def.count)
            .fill(null)
            .flatMap((x, i) => {
              if (space_def.type < 1 || space_def.type > 5) {
                return null;
              }
              return (
                <div
                  key={space_def.type + i}
                  className="BookingCapacityIcon"
                  style={{
                    backgroundImage:
                      'url(' + CapacityTypes[space_def.type] + ')',
                  }}
                />
              );
            }),
        )}
      </div>
      <div className="BookingInfo">
        <div className="Address">{address + city}</div>
        <div className="Assignment ColorGrey">
          {booking.space_on.length > 0
            ? text('pickup') + ` ${full_time}`
            : text('dropoff') + ` ${full_time}`}
        </div>
      </div>
    </div>
  );
};

interface BookingsProps {
  bookings: CurrentEvent[] | null;
}

const Booking: FC<BookingsProps> = ({ bookings }) => {
  const config = withConfig.getCurrentConfig() as Config;
  if (!bookings) {
    return <div className="EmptyBooking">{text('loading')}</div>;
  } else if (bookings.length === 0) {
    return (
      <div className="EmptyBooking">
        <Car className="EmptyGraphics" />
        <div className="EmptyText">
          <p className="EmptyTextTitle">{text('no_bookings')}</p>
          <p className="EmptyTextSubtitle"> {text('no_bookings_details')}</p>
        </div>
      </div>
    );
  }

  const color_by_id: { [id: string]: string } = {};
  let color_idx = 0;

  return (
    <div className="BookingListContainer">
      {bookings.map((booking, i) => {
        if (!color_by_id[booking.booking_id]) {
          color_by_id[booking.booking_id] =
            config.booking_colors[color_idx++ % config.booking_colors.length];
        }
        return (
          <BookingInfo
            key={booking.booking_id + '.' + i}
            booking={booking}
            index={i + 1}
            color={color_by_id[booking.booking_id]}
          />
        );
      })}
    </div>
  );
};

export default Booking;
