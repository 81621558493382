import oh, { TypedDictionary } from 'output-helpers';

export type TextKeys =
  | 'address'
  | 'all'
  | 'arrival_time'
  | 'bus_information'
  | 'busses'
  | 'capacity'
  | 'car'
  | 'choose_vehicle_id'
  | 'close'
  | 'disruptions'
  | 'description'
  | 'dropoff'
  | 'find'
  | 'freight'
  | 'garage'
  | 'group_display_text'
  | 'human'
  | 'invalid_address'
  | 'invalid_vehicle_id'
  | 'last_report'
  | 'line_traffic'
  | 'loading'
  | 'minibus_w_bed'
  | 'minibus'
  | 'minutes_short'
  | 'next_bus_stop'
  | 'no_bookings_details'
  | 'no_bookings'
  | 'no_results'
  | 'pickup'
  | 'position'
  | 'punctuality'
  | 'search_result'
  | 'stairclimber'
  | 'stretcher'
  | 'unscheduled_single'
  | 'unscheduled'
  | 'vehicle_id'
  | 'vehicles'
  | 'wheelchair_heavy'
  | 'wheelchair';

const dictionary: TypedDictionary<Dictionary> = {
  'sv-SE': {
    address: 'adress',
    all: 'alla',
    arrival_time: 'ankomst',
    bus_information: 'buss information linje',
    busses: 'linjetrafik', //"Linjetrafik"
    capacity: 'kapacitet',
    car: 'personbil',
    choose_vehicle_id: 'välj fordonsid',
    close: 'stäng',
    description: 'beskrivning',
    disruptions: 'störningar',
    dropoff: 'lämna',
    find: 'hitta',
    freight: 'gods',
    group_display_text: 'område',
    garage: 'garage',
    human: 'gående',
    invalid_address: 'ogiltig address',
    invalid_vehicle_id: 'ogiltigt fordonsid',
    last_report: 'senaste rapporten',
    line_traffic: 'linjetrafik',
    loading: 'laddar',
    minibus_w_bed: 'bårbil',
    minibus: 'specialfordon',
    minutes_short: 'min',
    next_bus_stop: 'nästa hållplats',
    no_bookings_details: 'det här fordonet har inga bokningar',
    no_bookings: 'inga bokningar',
    no_results: 'inga resultat',
    pickup: 'hämta',
    position: 'position',
    punctuality: 'punktlighet',
    search_result: 'sökresultat',
    stairclimber: 'trappklättrare',
    stretcher: 'liggade',
    unscheduled_single: 'obokad',
    unscheduled: 'obokade',
    vehicle_id: 'fordonsid',
    vehicles: 'fordon',
    wheelchair_heavy: 'tung rullstol',
    wheelchair: 'rullstol',
  },
  'en-US': {
    address: 'address',
    all: 'all',
    arrival_time: 'arriving',
    bus_information: 'bus information route',
    busses: 'bus service', //"Linjetrafik"
    capacity: 'capacity',
    car: 'car',
    choose_vehicle_id: 'choose vehicle id',
    close: 'close',
    description: 'description',
    disruptions: 'disruptions',
    dropoff: 'dropoff',
    find: 'find',
    freight: 'freight',
    group_display_text: 'area',
    garage: 'garage',
    human: 'pedestrian',
    invalid_address: 'invalid address',
    invalid_vehicle_id: 'invalid vehicle id',
    last_report: 'last report',
    line_traffic: 'bus services',
    loading: 'loading',
    minibus_w_bed: 'stretchercar',
    minibus: 'specialvehicle',
    minutes_short: 'min',
    next_bus_stop: 'next stop',
    no_bookings_details: 'this vehicle has no bookings',
    no_bookings: 'no bookings',
    no_results: 'no results',
    pickup: 'pickup',
    position: 'position',
    punctuality: 'punctuality',
    search_result: 'search result',
    stairclimber: 'stairclimber',
    stretcher: 'stretcher',
    unscheduled_single: 'unscheduled',
    unscheduled: 'unscheduled',
    vehicle_id: 'vehicle id',
    vehicles: 'vehicles',
    wheelchair_heavy: 'heavy wheelchair',
    wheelchair: 'wheelchair',
  },
};

type PartialDictionary = {
  'sv-SE': Partial<Dictionary>;
  'en-US': Partial<Dictionary>;
};

const bt: PartialDictionary = {
  'sv-SE': {
    minibus: 'buss',
  },
  'en-US': {
    minibus: 'bus',
  },
};
const bt_dictionary: TypedDictionary<Dictionary> = {
  'sv-SE': {
    ...dictionary['sv-SE'],
    ...(bt['sv-SE'] as Dictionary),
  },
  'en-US': {
    ...dictionary['en-US'],
    ...(bt['en-US'] as Dictionary),
  },
};

const st_dictionary: TypedDictionary<Dictionary> = {
  ...dictionary,
};

const translate = (key: keyof Dictionary, capitalize = true): string => {
  return String(oh.translateTyped<Dictionary>(key, capitalize));
};

export type Dictionary = { [key in TextKeys]: string };
export { translate, st_dictionary, bt_dictionary };
