export const paraRunNameToUnitTypeNumber = (para_run_name: string): number => {
  const vehicle_type = parseInt(para_run_name[1].toString(), 10);

  const type_map: { [index: string]: number } = {
    1: 5,
    2: 2,
    3: 6,
    4: 7,
    5: 7,
    6: 2,
    7: 2,
    9: 7,
  };

  if (!Object.prototype.hasOwnProperty.call(type_map, vehicle_type)) {
    throw new Error('ST: Invalid 2nd digit of paraRunName: ' + vehicle_type);
  }

  return type_map[vehicle_type];
};

export const numberTypeToUnitType = (unit_type_number: number): UnitType => {
  switch (unit_type_number) {
    case 6:
      return 'minibus_w_bed';
    case 2:
      return 'minibus';
    case 5:
      return 'car';
    case 7:
      return 'stairclimber';
    default: {
      throw new Error(
        'ST: Invalid numeric type in UnitInfo: ' + unit_type_number,
      );
    }
  }
};

export const getGroup = (inp: string | number): number => {
  const val = parseInt(inp.toString()[0], 10);
  if (isFinite(val)) {
    return val;
  }
  throw new Error("BT: Unable to extract first int from '" + inp + "'.");
};

export const filterValidUnits = (u: UnitInfoRes): boolean => {
  if (Number.isFinite(parseInt(u.ParaRunName.slice(0, 1), 10))) {
    const digit_two = parseInt(u.ParaRunName.slice(1, 2), 10);
    if (digit_two === 9 || (digit_two >= 1 && digit_two <= 7)) {
      return true;
    }
  }
  console.warn(`BT: Invalid ParaRunName: ${u.ParaRunName}`);
  return false;
};

export const filterBusses = (b: VehicleStateRes): boolean => {
  if (b) {
    return true;
  }
  return false;
};

export const convertUnitCapacity = (res: UnitCapacityRes): UnitCapacity => {
  return {
    max: res.Max,
    min: res.Min,
    name: res.Name,
    type: res.Type,
  };
};
