import React, { FC } from 'react';
import { Button } from '@blueprintjs/core';

import IconMarker from '../img/search/marker.svg';
import IconCar from '../img/search/car.svg';
import IconMarkerToMarker from '../img/search/marker_marker.svg';
import IconMarkerToCar from '../img/search/marker_car.svg';

import { SearchType } from './Search';

interface SearchTypeButtonProps {
  type: SearchType;
  current: SearchType;
  setType: React.Dispatch<React.SetStateAction<SearchType>>;
}
const icon_by_type: { [type in SearchType]: string } = {
  vehicle: IconCar,
  address: IconMarker,
  address_to_address: IconMarkerToMarker,
  address_to_vehicle: IconMarkerToCar,
  none: IconCar,
};

const switchType = (
  current: SearchType,
  btn: SearchType,
  setType: React.Dispatch<React.SetStateAction<SearchType>>,
): void => {
  if (current === btn) {
    setType('none');
  } else {
    setType(btn);
  }
};

const SearchTypeButton: FC<SearchTypeButtonProps> = ({
  type,
  current,
  setType,
}) => {
  return (
    <Button
      minimal
      className="Button"
      active={current === type}
      onClick={() => switchType(current, type, setType)}
    >
      <div
        className="ButtonImg"
        style={{ backgroundImage: 'url(' + icon_by_type[type] + ')' }}
      />
    </Button>
  );
};

export default SearchTypeButton;
