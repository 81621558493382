import React, { FC, useState } from 'react';
import { Text, Icon } from '@blueprintjs/core';
import withConfig from 'with-config';

import text from '../text';
import { generateGroups, legendTypes } from './legend_funcs';

import IconP from '../img/legend/p.svg';
import IconB from '../img/legend/b.svg';
import IconBB from '../img/legend/bb.svg';
import IconS from '../img/legend/s.svg';
import IconL from '../img/legend/l.svg';
import IconT from '../img/legend/t.svg';
import './LegendStyle.css';
import { Config } from '../config';

interface GroupProps {
  group: number;
  selected: boolean;
  onClick: () => void;
  config: Config;
}

const Group: FC<GroupProps> = ({ group, selected, onClick, config }) => {
  const hex_color = config.hex_by_color[config.color_by_group[group]];
  return (
    <div className={selected ? 'Group Selected' : 'Group'} onClick={onClick}>
      <div className="ColorSquare" style={{ backgroundColor: hex_color }} />
      {text('group_display_text')} {group}
    </div>
  );
};

const Closed: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <div className="Legend Closed" onClick={onClick}>
      {/* <H1 className="ColorWhite">L</H1> */}
      <Icon icon="menu-open" />
    </div>
  );
};

const icon_by_type: { [key in ServiceName]: { [key in UnitType]: string } } = {
  ntd_ui_skane: {
    car: IconP,
    minibus: IconS,
    minibus_w_bed: IconL,
    stairclimber: IconT,
  },
  ntd_ui_bt: {
    car: IconP,
    minibus: IconB,
    minibus_w_bed: IconBB,
    stairclimber: IconT,
  },
};

interface LegendProps {
  show: boolean;
  enabled_groups: Set<number>;
  enabled_types: Set<UnitType>;
  onGroupClick: (group: number) => void;
  onTypeClick: (type: UnitType) => void;
  vehicles: UnitInfo[];
}

const Legend: FC<LegendProps> = ({
  show,
  enabled_groups,
  enabled_types,
  onGroupClick,
  onTypeClick,
  vehicles,
}) => {
  const config = withConfig.getCurrentConfig() as Config;
  const unit_types: UnitType[] = legendTypes(config);
  const groups = generateGroups(config, vehicles);

  const [closed, setClosed] = useState<boolean>(false);
  if (!show) {
    return null;
  }

  if (closed) {
    return <Closed onClick={() => setClosed(false)} />;
  }

  return (
    <div className="Legend ColorWhite">
      <div className="HideBtn" onClick={() => setClosed(true)}>
        <Icon icon="menu-closed" />
      </div>
      {groups && (
        <div className="GroupContainer">
          <div className="GroupsLeft">
            {groups[0].map((group) => (
              <Group
                key={'group_' + group}
                group={group}
                selected={enabled_groups.has(group)}
                onClick={() => onGroupClick(group)}
                config={config}
              />
            ))}
          </div>
          <div className="GroupsRight">
            {groups[1].map((group) => (
              <Group
                key={'group_' + group}
                group={group}
                selected={enabled_groups.has(group)}
                onClick={() => onGroupClick(group)}
                config={config}
              />
            ))}
          </div>
        </div>
      )}
      <div className="TypeContainer">
        {unit_types.map((type) => (
          <div
            key={'type_' + type}
            className={enabled_types.has(type) ? 'Type Selected' : 'Type'}
            onClick={() => onTypeClick(type)}
          >
            <div
              className="Icon"
              style={{
                backgroundImage:
                  'url(' + icon_by_type[config.service_name][type] + ')',
              }}
            />
            <Text>{text(type)}</Text>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Legend;
