import React, { FC } from 'react';

import text from '../text';
import './GarageDetailsStyle.css';

interface Props {
  /** The currently selected bus details. */
  garage?: Garage;
}

const GarageDetails: FC<Props> = ({ garage }) => {
  if (!garage) {
    return null;
  }
  return (
    <div className="Bubble GarageDetailsBubble">
      <div className="BubbleHeader">
        <p className="BubbleTitle">
          {text('garage')} {garage.name}
        </p>
      </div>
      <div className="Body">
        <div className="HeadingText">{text('position')} WGS84 (lat, long)</div>
        <div className="ValueText">
          <strong>
            {garage.location.lat}, {garage.location.lng}
          </strong>
        </div>
        <div className="HeadingText">{text('description')}</div>
        <div className="ValueText">{garage.description}</div>
      </div>
    </div>
  );
};

export default GarageDetails;
